<template>
    <div class="contact-wrapper">
        <div @click="toggleOpen"
             class="circle-button">
            <i class="fa fa-comments" aria-hidden="true"></i>
        </div>
        <ContactComponent v-if=isOpen  endReached=endReached/>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import { env, log } from '@/bootstrap';
   
   
    import ContactComponent from './ContactComponent'
    export default {
        name: 'Contact',
        components:{
            ContactComponent,
        },
        data() {
            return {
                isOpen: false,
                relativeButtonBottom: null,
                stickyButtonBottom: null,
             
            };
        },
                   
        
        methods: {
            ...mapActions('contact', []),
            toggleOpen() {
                this.isOpen = !this.isOpen;
                if (this.isOpen){
                    window._uxa = window._uxa || [];
                    window._uxa.push(['setCustomVariable', 1, 'pagetype', 'widget']);
                    window._uxa.push(['setCustomVariable', 2, 'pagecategory1', 'contact']);
                    window._uxa.push(['setCustomVariable', 3, 'pagecategory2', '']);
                    window._uxa.push(['setCustomVariable', 4, 'pagecategory3', '']);
                    window._uxa.push(['setCustomVariable', 5, 'pagecategory4', '']);
                    window._uxa.push(['setCustomVariable', 6, 'pagecategory5', '']);
                    window._uxa.push(['setCustomVariable', 10, 'message', '']);
                    window._uxa.push(['trackPageview', window.location.pathname+window.location.hash.replace('#','?__')+'?cs-popin-contact']);
                }
                    
            },
          
            onScroll() {
                if (this.relativeButtonBottom && this.stickyButtonBottom) {
                    const scrollY = (typeof window.scrollY === 'undefined')
                        ? window.pageYOffset
                        : window.scrollY;
                    const viewportBottom = scrollY + window.innerHeight;
                    const documentHeight = document.body.offsetHeight;
                    this.endReached = viewportBottom > documentHeight -
                        (this.relativeButtonBottom - this.stickyButtonBottom);
                }
            }
        },
        created() {
            const { country, language } = env();
            if (!country || !language) {
                this.displayContactInfo = false;
            }

            this.removeScroll = this.$events.native('scroll', this.onScroll);
        },
        mounted() {
            const { contact } = this.$refs;
            if (contact) {
                const style = window.getComputedStyle
                    ? window.getComputedStyle(contact, null)
                    : contact.currentStyle;
                this.relativeButtonBottom = parseInt(style.bottom);
                this.endReached = false;
                this.$nextTick(() => {
                    this.stickyButtonBottom = parseInt(style.bottom);
                });
            }
        },
        destroyed() {
            this.removeScroll();
        }
    };
</script>