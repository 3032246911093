import Vue from 'vue';
import { env, log, translations, routes } from '@/bootstrap';
import { registries, COMMON_REGISTRY, CLOCK_TICK, LANGUAGE_UPDATED, UI_STORE_CREATED } from '@/events';
import { updateLoginState, updateTopBarStatus, updateMenu } from '@/libs/ui';
import { timestampSeconds, translateText } from '@/libs/util';
import { services } from '@/bootstrap';
import { createRequestAdapter } from '@/libs/adapter';
import to from 'await-to-js';


let trans={};
const getTranslation = (lang, key, replacements = {}) => {
    const fallback = trans.en;
    const messages = trans[lang];
    let text = messages ? messages[key] : null;
    if (!messages || !text) {
        log(`Translations not found for ${lang} with ${key}`, 'error', 'ui-store');
        text = fallback[key]
    }
    return translateText(text, replacements);
};

export default {
    namespaced: true,
    state: {
        isMobile: false,
        secondsInterval: null,
        bodyOverflowHidden: false,
        disableNewsletterMetatagName: 'disable-newsletter-popup',
        httpMenu:null,
        isMenu:false,
    },
    mutations: {
        SET_STATE(state, [key, value]) {
            Vue.set(state, key, value);
        },
        SET_MOBILE(state, bool) {
            state.isMobile = bool;
        },
        SET_MENU(state, bool) {
            state.isMenu = bool;
        },
        START_SECONDS_INTERVAL(state) {
            if (state.secondsInterval) clearInterval(state.secondsInterval);
            state.secondsInterval = setInterval(() => registries(COMMON_REGISTRY).emit(CLOCK_TICK), 1000);
        },
        STOP_SECONDS_INTERVAL(state) {
            clearInterval(state.secondsInterval);
        },
    },
    actions: {
        async init({ commit, dispatch, getters, rootGetters }) {
            
            registries(COMMON_REGISTRY).on(CLOCK_TICK, () => {
                const { 'user/sessionData': session } = rootGetters;
                if (session) {
                    dispatch('renderTime', session);
                    //dispatch('requestMenu');
                   
                } else {
                    dispatch('renderCookies');
                }
            });
            commit('START_SECONDS_INTERVAL');
            
            registries(COMMON_REGISTRY).emit(UI_STORE_CREATED, getters);
            registries(COMMON_REGISTRY).on(LANGUAGE_UPDATED,() => {
                trans = translations['widget-login'];
            });
            commit('SET_STATE', [
                'httpMenu',
                createRequestAdapter({
                    url: routes('URL_HARTING_MENU'),
                    auth: {
                        username: env('HTTP_AUTH_WEBSITE_USERNAME'),
                        password: env('HTTP_AUTH_WEBSITE_PASSWORD'),
                    },
                })
            ]);
        },
        setMobile({ commit }, bool) {
            commit('SET_MOBILE', bool);
        },
        updateSession(_, session) {       
            updateLoginState(session);
        },
        renderTime({dispatch, rootGetters }, session) {
            if (!session || session.validUntil==undefined || session.validUntil <= 0) {
                return;
            }
            const diff = Math.min(60 * 99, rootGetters['cookies/getCookie']('validUntil') - timestampSeconds());
            if (diff > 0) {
                const replacements = {
                    minutes: parseInt(diff / 60).toString().padStart(2, '0'),
                    seconds: parseInt(diff % 60).toString().padStart(2, '0'),
                };
                updateTopBarStatus(getTranslation(env('languageShort'), 'topbar_autologout', replacements), 'fa-clock');
                let cart = rootGetters['cookies/getCookie']('CartCount');
                let wishlist = rootGetters['cookies/getCookie']('WhisListCount');
                if (cart != null && cart != undefined){
                    dispatch('user/updateCartAmount', cart ,{root:true});
                }
                if (wishlist != null && wishlist != undefined){
                    dispatch('user/updateWatchlistAmount', wishlist ,{root:true});
                }
            } else {
                // TODO: Reload?              
                updateTopBarStatus(getTranslation(env('languageShort'), 'topbar_session_outdated'), 'fa-exclamation-triangle');
                //logout efectively
                if(rootGetters['cookies/getCookie']('validUntil') != 0 ){//already logout
                    services('$store').dispatch('user/requestLogout',{ withRedirect :true,force:true});

                }
                dispatch('user/updateCartAmount', 0 ,{root:true});
               
            }
        },
        renderCookies({dispatch, rootGetters}){
            let wishlist = rootGetters['cookies/getCookie']('WhisListCount');           
            if (wishlist != null && wishlist != undefined){
                dispatch('user/updateWatchlistAmount', wishlist ,{root:true});
            }
        },
        setBodyOverflowHidden({ commit }, hidden) {
            if (hidden) {
                commit('SET_STATE', ['bodyOverflowHidden', true]);
                document.body.classList.add('hide-overflow');
            } else {
                commit('SET_STATE', ['bodyOverflowHidden', false]);
                document.body.classList.remove('hide-overflow');
            }
        },
        async requestMenu({ state ,commit}) { 
            if (!state.isMenu){
                const menuStateRequest = state.httpMenu.get();
                const [menuStateError, menuStateResponse] = await to(menuStateRequest);
           
                if (menuStateError) {
                    log(menuStateError, 'error', 'hybris-session');
                }
                else{
                    updateMenu(menuStateResponse.data);
                }
                commit('SET_STATE',['isMenu',true]);
            }
        },
    },
    getters: {
        isMobile: state => state.isMobile,
        bodyOverflowHidden: state => state.bodyOverflowHidden,
        disableNewsletterMetatag: state => {
            const meta = document.querySelector(`meta[name=${state.disableNewsletterMetatagName}]`);
            if (meta) return meta.getAttribute('content') === 'true';
            return false;
        }
    }
};