<template>
    <span v-if="show"
          :class="badgeClasses">
        {{ amount }}
    </span>
</template>

<script>
    export default {
        name: 'Badge',
        props: {
            getter: {
                type: String,
                default: '',
            },
            highlighted: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            show() {
                return this.amount > 0;
            },
            amount() {
                const getter = this.$store.getters[this.getter];
                if (getter)
                    return getter;
                return 0;
            },
            badgeClasses() {
                return {
                    'badge': true,
                    'badge--highlighted': this.highlighted,
                };
            },
        },
    };
</script>

<style scoped>

</style>