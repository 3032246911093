import { createVue, createDOMHook, log, routes } from '@/bootstrap';
import TermsDialog from './TermsDialog';
import { dom } from './config';

createDOMHook({
    id: dom.hookId,
});

createVue({
    component: TermsDialog,
    element: `#${dom.hookId}`,
});


log('Widget initialized.', 'info', 'download-terms');
