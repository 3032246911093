import LoginForm from './LoginForm';
import ConfiguratorLoginForm from './ConfiguratorLoginForm';
import LoginProvider from './LoginProvider';
import { createVue, createDOMHook, env, log, routes } from '@/bootstrap';
import { dom } from './config';

// Login widgets for HDBP and Shop
const initLoginWidgets = () => {
    // Create wrapper if it is missing
    if (!document.getElementById(dom.containerId)) {
        createDOMHook({
            id: dom.containerId,
        });
    }

    createDOMHook({
        id: dom.loginHookId,
        //container: `#${dom.containerId}`,
    });

    createDOMHook({
        id: dom.configuratorLoginHookId,
        container: `#${dom.containerId}`,
    });

    createVue({
        component: LoginForm,
        element: `#${dom.loginHookId}`,
    });

    createVue({
        component: ConfiguratorLoginForm,
        element: `#${dom.configuratorLoginHookId}`,
    });
};

// Login functionality for login.harting.com
const initLoginPage = () => {
    createDOMHook({
        id: dom.hookId,
        container: document.body,
    });

    createVue({
        component: LoginProvider,
        element: `#${dom.hookId}`,
    });
};

// Login button from shared header
const loginButtonWrapper = document.getElementById(dom.containerId);
// Buttons for configurator login
const configuratorButton = document.querySelector(dom.configuratorLoginRequired);
if (loginButtonWrapper || configuratorButton) {
    if (!env('isChina')) initLoginWidgets();
} else {
    initLoginPage();
}

log('Widgets initialized.', 'info', 'login');
