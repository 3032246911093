import Vue from 'vue';
import to from 'await-to-js';
import { env, log, routes } from '@/bootstrap';
import { createRequestAdapter } from '@/libs/adapter';
import { transformArticle, transformProductCategory, transformProductSubcategory } from '@/libs/handler';
import { registries, UNIQUE_REGISTRY, SEARCH_STORE_CREATED } from '@/events';
import { tryValue, domainConfigForLanguage } from '@/libs/util';

export const isValidSearchTerm = term => term && typeof term === 'string';

const checkDomainContentSearchability = cfg =>
    tryValue(cfg, 'domain_configuration.enable_content_search', true) === true;

const checkDomainProductSearchability = cfg =>
    tryValue(cfg, 'domain_configuration.disable_hybris_search', false) === false;

export default {
    namespaced: true,
    state: {
        httpDrupal: null,
        httpHybris: null,
        searchTerm: '',
        redirect: '',
        articles: [],
        productCategories: [],
        articleSuggestions: [],
        productCategorySuggestions: [],
        searchingArticles: false,
        searchingProductCategories: false,
        domainContentIsSearchable: true,
        domainProductsAreSearchable: true,
    },
    mutations: {
        SET_STATE(state, { key, value }) {
            Vue.set(state, key, value);
        },
        UPDATE_SEARCH_TERM(state, value) {
            state.searchTerm = value;
        },
    },
    actions: {
        init({ commit, getters }) {
            commit('SET_STATE', {
                key: 'httpDrupal',
                value: createRequestAdapter({
                    url: routes('URL_DRUPAL_SEARCH'),
                    auth: {
                        username: env('HTTP_AUTH_WEBSITE_USERNAME'),
                        password: env('HTTP_AUTH_WEBSITE_PASSWORD'),
                    },
                })
            });
            commit('SET_STATE', {
                key: 'httpHybris',
                value: createRequestAdapter({
                    url: routes('URL_HARTING_SEARCH'),
                    auth: {
                        username: env('HTTP_AUTH_SHOP_USERNAME'),
                        password: env('HTTP_AUTH_SHOP_PASSWORD'),
                    },
                })
            });
            registries(UNIQUE_REGISTRY).emit(SEARCH_STORE_CREATED, getters);
        },
        updateSearchTerm({ commit }, term) {
            commit('UPDATE_SEARCH_TERM', term);
        },
        async requestSearch({ commit, dispatch, state }) {
            let { searchTerm } = state;
            if (!isValidSearchTerm(searchTerm))
                return;

            const domainCfg = domainConfigForLanguage(env('country'));
            const contentIsSearchable = checkDomainContentSearchability(domainCfg);
            const productsAreSearchable = checkDomainProductSearchability(domainCfg);
            let drupalsearch=searchTerm.replace(' -',' ');
            const queryArticles = {
                query: drupalsearch,
                langcode: env('hdbpLanguage'),
            };
            const queryProductCategories = {
                text: searchTerm,
                widget: 'true', // Added for sycor in HDBPSQA-206
            };
            const handleApiError = (api, err) => {
                (api === 'drupal') && commit('SET_STATE', { key: 'searchingArticles', value: false });
                (api === 'hybris') && commit('SET_STATE', { key: 'searchingProductCategories', value: false });
                log(err, 'error', `${api} api`);
            };

            if (domainCfg) {
                queryArticles.country_code = domainCfg.machine_name;
            }

            commit('SET_STATE', { key: 'domainContentIsSearchable', value: contentIsSearchable });
            commit('SET_STATE', { key: 'domainProductsAreSearchable', value: productsAreSearchable });

            // Request searches for given apis
            let drupalSearch, hybrisSearch;
            if (contentIsSearchable) {
                drupalSearch = dispatch('searchDrupal', { params: queryArticles })
                    .catch(err => handleApiError('drupal', err));
            }
            if (productsAreSearchable) {
                hybrisSearch = dispatch('searchHybris', { params: queryProductCategories })
                    .catch(err => handleApiError('hybris', err));
            }

            // Wait for them to settle
            await Promise.all([drupalSearch, hybrisSearch]);

            // Redirect if only one product was found
            if (state.articles.length === 0 && state.productCategories.length === 1 && state.redirect.length > 0) {
                location.href = routes('URL_HARTING_RAW', state.redirect);
            }
        },
        async searchDrupal({ commit, state }, { params }) {
            // Send request
            commit('SET_STATE', { key: 'searchingArticles', value: true });
            const search = state.httpDrupal.get({ params });
            const [err, res] = await to(search);
            if (err) {
                log(`Drupal API call failed! ${err.message}`, 'error', 'search');
                commit('SET_STATE', { key: 'searchingArticles', value: false });
                return;
            }

            // Response contains no data
            if (typeof res.data !== 'object') {
                log('Harting API response does not contain data!', 'error', 'search');
                commit('SET_STATE', { key: 'articles', value: [] });
                commit('SET_STATE', { key: 'searchingArticles', value: false });
                return;
            }

            // Parse response
            const articles = res.data.map(article => {
                article = transformArticle(article);
                article.countryCode = env('country').toUpperCase();
                return article;
            });
            commit('SET_STATE', { key: 'articles', value: articles });
            commit('SET_STATE', { key: 'searchingArticles', value: false });

            return articles;
        },
        async searchHybris({ commit, state }, { params }) {
            // Send request
            commit('SET_STATE', { key: 'searchingProductCategories', value: true });
            const search = state.httpHybris.get({ params });
            const [err, res] = await to(search);
            if (err) {
                log(`Hybris API call failed! ${err.message}`, 'error', 'search');
                commit('SET_STATE', { key: 'searchingProductCategories', value: false });
                return;
            }

            // Check if response contains data
            const { data } = res;
            if (typeof data !== 'object' || typeof data.Result === 'undefined') {
                log('Harting API response does not contain valid data!', 'error', 'search');
                commit('SET_STATE', { key: 'productCategories', value: [] });
                commit('SET_STATE', { key: 'searchingProductCategories', value: false });
                return;
            }

            // Check if redirect is available
            if (data.TotalResults === 1 && typeof data.RedirectTo === 'string') {
                commit('SET_STATE', { key: 'redirect', value: data.RedirectTo });
            }

            // Set suggestions if found
            if (typeof data.Suggestions !== 'undefined') {
                commit('SET_STATE', { key: 'productCategorySuggestions', value: data.Suggestions });
            }

            // Check if result needs restructuring, usually when searching for product numbers
            let productCategories = data.Result;
            if (data.ResultType === 'product') {
                // Iterate over subcategories with categories in them
                productCategories = Object.keys(productCategories)
                    .reduce((struct, subcatId) => {
                        const subcat = productCategories[subcatId];

                        if (Array.isArray(subcat.categories) && subcat.categories.length > 0) {
                            const cat = subcat.categories[0]; // Choose first category everytime?
                            if (subcat.images) {
                                subcat.image = Array.from(subcat.images)
                                    .filter(img => img.format === 'product')[0];
                            }
                            subcat.numberOfSearchResults = 1;
                            cat.numberOfSearchResults = 1;
                            cat.directSubCategories = [subcat];
                            struct[cat.code] = cat;
                        }

                        return struct;
                    }, {});
            }

            // Parse categories with nested subcategories
            productCategories = Object.keys(productCategories)
                .reduce((result, categoryId) => {
                    const categoryData = productCategories[categoryId];
                    const category = transformProductCategory(categoryData);
                    const subcategories = [];
                    if (data.ResultType != 'product')    category.url=category.url+'&q='+params.text;
                    for (const subcategoryId in categoryData['directSubCategories']) {
                        const subcategoryData = categoryData['directSubCategories'][subcategoryId];
                        const subcateg=transformProductSubcategory(subcategoryData)
                        if (data.ResultType != 'product')  subcateg.url=subcateg.url+'&q='+params.text;   
                        subcategories.push(subcateg);
                    }

                    category.subcategories = subcategories;
                    result.push(category);
                    return result;
                }, []);

            commit('SET_STATE', { key: 'productCategories', value: productCategories });
            commit('SET_STATE', { key: 'searchingProductCategories', value: false });

            return productCategories;
        },
    },
    getters: {
        searchTerm: state => state.searchTerm,
        redirect: state => state.redirect,
        articles: state => state.articles,
        productCategories: state => state.productCategories,
        articleSuggestions: state => state.articleSuggestions,
        productCategorySuggestion: state => state.productCategorySuggestions[0],
        searchingArticles: state => state.searchingArticles,
        searchingProductCategories: state => state.searchingProductCategories,
        domainContentIsSearchable: state => state.domainContentIsSearchable,
        domainProductsAreSearchable: state => state.domainProductsAreSearchable,
    }
};