import { dom as loginDom } from './config';
import { dom as downloadTermsDom } from '@/widgets/download-terms/config';

// Returns meta information for login handler
export const loginMetaInformation = (event = {}) => {
    const { currentTarget } = event;
    const { location: { href } } = window;
    const meta = {
        leadoriginUrl: href,
    };

    const leadoriginMetaTag = document.querySelector(loginDom.leadoriginMeta);
    if (leadoriginMetaTag) {
        meta.leadoriginType = leadoriginMetaTag.getAttribute('content');
    }

    if (currentTarget) {
        const leadoriginTargetUrl = currentTarget.getAttribute(downloadTermsDom.dataDownload);
        const leadoriginTargetType = currentTarget.getAttribute(loginDom.leadoriginTargetType);
        if (leadoriginTargetUrl) meta.leadoriginTargetUrl = leadoriginTargetUrl;
        if (leadoriginTargetType) meta.leadoriginTargetType = leadoriginTargetType;
    }

    return meta;
};