import { env } from './env';

let loggerPreventLogging = false;
let loggerEnvPrefix = 'not-set';

// Source: https://stackoverflow.com/a/19807441
const getMyName = () => {
    const error = new Error();
    const lastStackFrameRegex = new RegExp(/.+\/(.*?):\d+(:\d+)*$/);
    const currentStackFrameRegex = new RegExp(/getScriptName \(.+\/(.*):\d+:\d+\)/);

    if (typeof error.stack !== `undefined`) {
        const lastSource = lastStackFrameRegex.exec(error.stack.trim());
        const curSource = currentStackFrameRegex.exec(error.stack.trim());

        if (lastSource && lastSource[1] !== '') {
            return lastSource[1];
        } else if (curSource) {
            return curSource[1];
        } else if (error.fileName !== undefined) {
            return error.fileName;
        }
    } else {
        return '';
    }
};

export const initLogger = (preventLogging = false) => {
    const nodeEnv = env('NODE_ENV').slice(0, 4);
    const buildNumber = env('BUILD_NUMBER');
    loggerEnvPrefix = `${nodeEnv}-${buildNumber}`;
    loggerPreventLogging = preventLogging;
};

export const log = (msg, level, name) => {
    if (!loggerPreventLogging){
        let scriptName = name || getMyName();
        let widgetName = 'missing';
        if (scriptName) {
            scriptName = scriptName.toLowerCase().split('.');
            if (scriptName.length > 0) {
                widgetName = scriptName[0].toUpperCase();
            }
        }

        level = (typeof level === 'string') ? level.toUpperCase() : 'INFO';

        console.log(`[${widgetName}/${loggerEnvPrefix}] ${level}: ${msg}`);
    }
};
