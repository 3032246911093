<template>
    <div>
        <a :href="hartingLink" 
           class="product-category-search-result-teaser__pre-title">
            ({{resultAmount}}) &nbsp; <span v-html="title"></span>
        </a>
        
        <Product v-for="product in subcategories"
                 :title="product.title"
                 :url="product.url"
                 :result-amount="product.resultAmount"
                 :image="product.image"
                 :key="product.id"/>
    </div>
</template>

<script>
    import Product from './Product'
    
    export default {
        name: 'ProductCategory',
        components: {
            Product,
        },
        props: {
            title: {
                type: String,
                required: true,
            },
            url: {
                type: String,
                required: true,
            },
            resultAmount: {
                type: [String, Number],
                required: true,
            },
            subcategories: {
                type: Array,
                default: () => [],
            },
          
        },
        computed: {
            hartingLink() {
                return this.$routes('URL_HARTING_RAW', `${this.$env('languageShort')}${this.url}`)
            }
        },
    };
</script>

<style scoped>

</style>