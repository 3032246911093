import { log, createVue, createDOMHook } from '@/bootstrap';
import Badge from './Badge';
import { dom, store } from './config';

createDOMHook({
    id: dom.cartHookId,
    container: dom.cartHookContainer,
});

createDOMHook({
    id: dom.watchlistHookId,
    container: dom.watchlistHookContainer,
});

const cart = createVue({
    component: Badge,
    element: `#${dom.cartHookId}`,
    props: {
        getter: store.cartGetter,
    }
});

const watchlist = createVue({
    component: Badge,
    element: `#${dom.watchlistHookId}`,
    props: {
        getter: store.watchlistGetter,
    }
});

log('Widget initialized.', 'info', 'badge');