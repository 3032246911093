import {  log, routes } from '@/bootstrap';
import {
   
    waitForEvents,
    COMMON_REGISTRY,
    SESSION_UPDATED,
} from '@/events';

var postdata=null;
var Items=[];
async function init(){
let result=await waitForEvents([
    [COMMON_REGISTRY, SESSION_UPDATED],
]);

  
  if (result[0] != null) {
  
    let campaign = document.querySelector('[name=campaignCode]');
    let campaignCode='';
    
    postdata={
        FormType:"PageView",
        email:result[0].mail,
        hybrisuser:result[0].uid,
        campaign: "",
        score:1,
        DatasetItem:[
            {FormFieldType:"Host",FormFieldValue:window.location.hostname},
            {FormFieldType:"Referrer",FormFieldValue:document.referrer},
            {FormFieldType:"PageTitle",FormFieldValue:document.title},
            {FormFieldType:"Uri",FormFieldValue:window.location.href},
        ]


    };

    postdata.DatasetItem=postdata.DatasetItem.concat(Items);
    if (campaign != null && campaign!=undefined ){
        campaignCode=campaign.content;
        postdata.DatasetItem.push({FormFieldType:"CampaignCode",FormFieldValue:campaignCode});
    }
   
    fetch( routes('URL_HYBRIS_TRACKING'),
    {
        credentials:'include',
        method: "POST",
        
        headers: {'Content-Type':'text/plain'},
        body:JSON.stringify( postdata ),
    });
  }
}

init();
window.addTrakingdata = (key,value) => {
    if (postdata==null){
        Items.push({FormFieldType:key,FormFieldValue:value});
    }else{
        postdata.DatasetItem.push({FormFieldType:key,FormFieldValue:value});
    }
    
};
window.Qualtrics=window.Qualtrics||[];
window.Qualtrics.language=document.getElementsByTagName('html')[0].getAttribute('lang');
log('Widget initialized.', 'info', 'tracking');