<template>
    <a @click="scrollDown"
       :class="buttonClasses">
    </a>
</template>

<script>
    import Velocity from 'velocity-animate';

    export default {
        name: 'ScrollIndicator',
        data() {
            return {
                userScrolled: false,
                displayIndicator: false,
                displayDelay: 3000,
                hideDelay: 10000,
                scrollDuration: 1000,
            };
        },
        computed: {
            buttonClasses() {
                return {
                    'circle-button': true,
                    'circle-button--scroll': true,
                    'circle-button--hidden': !this.displayIndicator,
                };
            },
        },
        methods: {
            show() {
                this.displayIndicator = true;
            },
            hide() {
                this.displayIndicator = false;
            },
            scrollDown() {
                const doc = document.body || document.getElementsByTagName('body')[0];
                const top = window.scrollY || window.pageYOffset;
                Velocity(doc, 'scroll', {
                    offset: top + window.innerHeight,
                    duration: this.scrollDuration,
                    easing: 'ease',
                    mobileHA: false, // Prevents iOS flicker
                });
                this.hide();
            },
            setTimingBehaviour() {
                setTimeout(() => {
                    if (!this.userScrolled) {
                        this.show();
                        setTimeout(this.hide, this.hideDelay);
                    }
                }, this.displayDelay);
            },
        },
        mounted() {
            this.$events.native('scroll', () => {
                this.userScrolled = true;
            });
            this.setTimingBehaviour();
        },
    };
</script>
