// NOTE: Some selectors are called id while being a class
export const dom = {
    containerId: 'login-button',
    loginHookId: 'hook-login-form',
    configuratorLoginHookId: 'hook-configurator-login-form',

    loginRequired: '[data-login-required=true]',
    configuratorLoginRequired: '[data-login-type=configurator][data-login-required=true]',
    loginButtonDesktopId: 'login-desktop',
    loginButtonMobileId: 'login-mobile',
    myHartingloginButtonDesktopId: 'myHarting-login-desktop',
    myHartingloginButtonMobileId: 'myHarting-login-mobile',
    myHartingButton: 'myHarting-desktop',
    accountMenuId: 'account-menu',
    dropdownMenuId:'user-card-dropdown__menu-inner',
    userIdClass: 'header-user-card__id',
    topBarGuiContainerId: 'top-bar__timer',
    topBarGuiIconId: 'top-bar__timer-icon',
    topBarGuiTextId: 'top-bar__timer-text',
    menuLogoutId: 'logout',
    accountIdClass: 'header-user-card__account-id',
    accountIdHiddenClass: 'header-user-card__account-id--hidden', 

    hideButtonClass: 'header-user-card__button--hidden',
    hideTopBarGuiClass: 'top-bar__timer--hidden',
    hideAccountIdClass: 'header-user-card__account-id--hidden',

    accountTypeServiceClass: 'is-service-account',
    accountTypeShopClass: 'is-shop-account',

    cartWrapperClass: 'fa-shopping-cart',
    cartBadgeClasses: 'badge badge--highlighted',

    watchlistWrapperClass: 'fa-star',
    watchlistBadgeClasses: 'badge badge--highlighted',

    leadoriginMeta: 'meta[name=leadorigin]',
    leadoriginTargetType: 'data-leadorigin-target-type',

    clickbutton:'header-user-card__dropdown-button'
};