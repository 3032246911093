import { env} from '@/bootstrap';

const { tryValue } = require(`./util`);

/*
 * Transformers take an object and return a restructured json
 */

export const transformArticle = item => {
    return {
        id: tryValue(item, ['nid']),
        url: tryValue(item, ['url']),
        title: tryValue(item, ['title']),
        category: tryValue(item, ['type']),
        langCode: tryValue(item, ['langcode']),
        domainAccess: tryValue(item, ['field_domain_access']),
    };
};

export const transformProductCategory = item => {
    return {
        id: tryValue(item, ['code']),
        title: tryValue(item, ['name']),
        url: tryValue(item, ['url']),
        resultAmount: tryValue(item, ['numberOfSearchResults']),
    };
};

export const transformProductSubcategory = item => {
    return {
        id: tryValue(item, ['code']),
        title: tryValue(item, ['name']),
        image: tryValue(item, ['image', 'url']),
        url: tryValue(item, ['url']),
        resultAmount: tryValue(item, ['numberOfSearchResults']),
    };
};

export const transformWatchlist = item => {
    return {
        id: tryValue(item, ['widhlistId']),
        publicId: tryValue(item, ['publicId']),
        name: tryValue(item, ['name']),
        entryCount: tryValue(item, ['numEntries']),
    };
};

export const transformContactPerson = item => {
    return {
        name: tryValue(item, ['subsidiary']),
        image: env('URL_DRUPAL_BASE')+'/sites/default/files/styles/meta_contact_person_image/public/2017-12/HARTING_Avatar_Male.jpg',
        email: tryValue(item, ['email']),
        phone: tryValue(item, ['phone']),
        isDefault: tryValue(item, ['is_default']),
    };
}