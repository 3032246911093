import { createVue, createDOMHook, log, routes } from '@/bootstrap';
import { dom } from './config';
import Search from './Search';


createDOMHook({
    id: dom.hookId,
    container: `#${dom.containerId}`,
});

createVue({
    component: Search,
    element: `#${dom.hookId}`,
});

log('Widget initialized.', 'info', 'search');