/**
 * Initializes reactive environment variables
 */

import Cookies from 'js-cookie';
import { reduceObject } from './util';
import { log } from './logger';
import {
    registries,
    UNIQUE_REGISTRY,
    LANGUAGE_CREATED,
    DOMAINS_UPDATE,
} from '@/events';
import languageConfig from '@/../assets/language-configuration';


const envPrefix = 'VUE_APP_';
const defaultLanguage = 'en';
const defaultCountry = 'US';
const shopDomainPlaceholder = '__DOMAIN_SHOP__';
const hdbpDomainPlaceholder = '__DOMAIN_HDBP__';
const idpDomainPlaceholder = '__DOMAIN_IDP__';
const hdbpLangPlaceholder = '__HDBP_LANG__';
const shopLangPlaceholder = '__SHOP_LANG__';
const countryPlaceholder = '__CTRY__';
const hybrisCountryPlaceholder = '__HYBRIS_CTRY__';
const countryLanguageCookieBaseNames = {
    drupal: 'country_language',
    hybris: 'current_country_language',
};

// Controls debug output
let _debug = false;
// Stores env variables
let _env = {};
// Stores routes with current language code
let _parsedRoutes = {};


/*----------------------------------------------------------------------------------------------------------------------
 * Environment
 */

/**
 * Return env variable for key or whole env if no key was given
 * Env keys can get prefixed with "VUE_APP_" to match keys in .env file
 *
 * @param key       Environment variable name
 * @param fallback? Default return value if key is not set
 * @returns {*}
 */
export const env = (key, fallback = undefined) => {
    if (key) {
        let val = _env[key];

        if (val === undefined && key.indexOf(envPrefix) !== 0) {
            key = `${envPrefix}${key}`;
            if (key in _env)
                val = _env[key];
        }

        return val !== undefined ? val : fallback;
    }

    return _env;
};

/**
 * Assigns environment variables and current country/language once
 */
export const initEnv = () => {
    const envIsEmpty = Object.keys(_env).length === 0;
    if (process && process.env && envIsEmpty)
        _env = process.env;

    _debug = (_env.NODE_ENV !== 'production' && _env.NODE_ENV !== 'test');

    const site = _currentSite();
    const htmlLang = _htmlLanguage();
    const countryLanguageCookie = countryLanguageCookieName(site);
    _env.site = site;
    _env.browserLanguage = htmlLang;
    _env.countryLanguage = Cookies.get(countryLanguageCookie);
    _env.micrositeId = document
        .querySelector('html')
        .getAttribute('data-microsite-id');
    // prettier-ignore
    console.log(`[ENV] Read cookie "${countryLanguageCookie}"`, _env.countryLanguage);

    // Check country language cookie
    if (_env.countryLanguage) {
        // Standard behaviour
        const { country, language } = _filterCountryLanguage(_env.countryLanguage, _env.micrositeId);
        _env.country = country;
        _env.language = language;
    } else {
        console.error(`[ENV] "${countryLanguageCookie}" cookie not found!`);
        _env.country = defaultCountry;
        _env.language = defaultLanguage;
    }

    // HACK: HARTHDBP-1511
    // Map "GB" to "UK" because of country inconsistencies between hybris and drupal
    if (_env.country.toUpperCase() === 'GB' || _env.country.toUpperCase() === 'UK') {
        _env.country = 'UK';
        _env.hybrisCountry = 'GB';
        log(`Mapped UK/GB: ${_env.country}, ${_env.hybrisCountry}`, 'info', 'env');
    } else {
        _env.hybrisCountry = _env.country;
    }

    // HACK: HARTHDBP-1681
    // Is true if widgets are executed in chinese client
    _env.isChina = window.location.hostname.endsWith('.com.cn');

    _env.languageShort = _shortLang(_env.language);

    // Override hdbpLanguage and shopLanguage if present
    const langCfg = _languageConfigForCookie(_env.countryLanguage);
    _assignLanguageConfig(langCfg);

    // prettier-ignore
    log(`Resulting country, language, languageShort: ${_env.country}, ${_env.language}, ${_env.languageShort}`, 'info', 'env');

    // Initialize
    _parsedRoutes = parseRoutes();
    registries(UNIQUE_REGISTRY).emit(LANGUAGE_CREATED);
};

// Override domains in env
export const updateDomains = ({ shop = null, hdbp = null, idp = null, } = {}) => {
    if (shop) _env.VUE_APP_DOMAIN_SHOP = shop;
    if (hdbp) _env.VUE_APP_DOMAIN_HDBP = hdbp;
    if (idp) _env.VUE_APP_DOMAIN_IDP = idp;
    _parsedRoutes = parseRoutes();
};

/*----------------------------------------------------------------------------------------------------------------------
 * Routes
 */

// Provides route for key or all routes if key is omitted
export const routes = (key, path = '') => {
    if (!key) {
        return _parsedRoutes;
    }

    let route = _parsedRoutes[key];
    if (!route && key.indexOf(envPrefix) !== 0) {
        // Try again with prefix if route is not found
        route = _parsedRoutes[`${envPrefix}${key}`];
    }
    return route ? route + path : undefined;
};

// Parse route patterns from env and replace placeholders
const parseRoutes = () => {
    return reduceObject(env(), (routes, key, val) => {
        if (key.indexOf('URL') !== -1) {
            routes[key] = val
                .replace(shopDomainPlaceholder, env('DOMAIN_SHOP'))
                .replace(hdbpDomainPlaceholder, env('DOMAIN_HDBP'))
                .replace(idpDomainPlaceholder, env('DOMAIN_IDP'))
                .replace(hdbpLangPlaceholder, env('hdbpLanguage'))
                .replace(shopLangPlaceholder, env('shopLanguage'))
                .replace(hybrisCountryPlaceholder, env('hybrisCountry').toUpperCase())
                .replace(countryPlaceholder, env('country').toUpperCase());
        }
        return routes;
    }, {});
};

/*----------------------------------------------------------------------------------------------------------------------
 * Utils
 */

export const countryLanguageCookieName = (site) => {
    const baseName = countryLanguageCookieBaseNames[site];
    const suffix = env('COOKIE_SUFFIX', 'unknown');
    return `${baseName}-${suffix}`;
};

// Hacky way to get domain names via env
export const getDomains = () => {
    // Default: prod
    let hdbp = 'www.harting.com';
    let shop = 'b2b.harting.com';

    if (env('COOKIE_SUFFIX') === 'qa') {
        hdbp = 'web.hdbp-qa.harting.com';
        shop = 'hdbp-qa.harting.com';
    }
    // TODO: Feature staging instances?

    // Add .cn to the domain for different china hosts
    if (env('isChina')) {
        hdbp += '.cn';
        shop += '.cn';
    }

    return { hdbp, shop };
};

const _languageConfigForCookie = countryLanguage =>
    Array.isArray(languageConfig) && languageConfig.find(cfg => cfg[`${_currentSite()}Cookie`] === countryLanguage);

const _languageConfigForLanguage = language =>
    Array.isArray(languageConfig) && languageConfig.find(cfg => cfg[`${_currentSite()}Language`] === language);

// Create hdbpLanguage and shopLanguage from config or defaults
const _assignLanguageConfig = (config) => {
    _env.hdbpLanguage = config ? config.drupalLanguage : env('language');
    _env.shopLanguage = config ? config.hybrisLanguage : env('languageShort');
};

/**
 * Extracts country and language from cookie
 *
 * @param countryLanguage Content of country language cookie
 * @param microSite Optional microsite id for country
 * @returns {{country: string, language: string}}
 */
const _filterCountryLanguage = (countryLanguage, microSite = null) => {
    let country = defaultCountry;
    let language = defaultLanguage;
    if (microSite) {
        countryLanguage = countryLanguage.slice(microSite.length);
    }

    const [filterCountry, ...filterRest] = countryLanguage.split('_');
    if (filterRest.length) {
        // First part is country, rest is language
        country = filterCountry;
        language = filterRest.join('_');
    } else {
        // Only one part found (edge case)
        country = filterCountry;
        language = filterCountry;
    }

    return {
        country: microSite ? microSite : country,
        language,
    };
};

const _htmlLanguage = () => {
    let lang = 'en';

    if (document && document.querySelector) {
        lang = document.querySelector('html').getAttribute('lang');
    }

    return lang;
};

const _shortLang = (language = defaultLanguage) => {
    if (language=="zh-hant"){
        language="zf";
    }
    if (language.length > 2) {
        language = language.slice(0, 2);
    }
    return language.toLowerCase();
};

/**
 * Extracts the plattform we are on by getting the first part of the domain (f.e. www.harting.com => "www").
 * Currently we check if first part belongs to hybris
 *
 * @returns {string} 'hybris' | 'drupal'
 */
const _currentSite = () => {
    let host = window.location.hostname;
    const port = window.location.port;

    // HACK: Remap host if widgets are run locally
    if (host === 'localhost') {
        if (port === '8081') {
            host = 'b2b.harting.com';
        } else if (port === '8082') {
            host = 'www.harting.com';
        } else if (port === '8083') {
            host = 'login.harting.com';
        }
    }
    const hybrisParts = ['b2b', 'hdbp-qa', 'ebusiness-demo'];
    const firstSubPart = host
        .split('.')
        .shift()
        .toLowerCase();

    if (hybrisParts.includes(firstSubPart)) {
        return 'hybris';
    }

    return 'drupal';
};
