<template>
    <a :href="hartingLink" 
       class="product-category-search-result-teaser">
        <div :style="teaserImage"
             class="product-category-search-result-teaser__image"></div>
        
        <div class="product-category-search-result-teaser__title">
            ({{ resultAmount }}) &nbsp; <span v-html="title"></span>
        </div>
    </a>
</template>

<script>
    export default {
        name: 'product',
        props: {
            title: {
                type: String,
                required: true,
            },
            url: {
                type: String,
                required: true,
            },
            resultAmount: {
                type: [String, Number],
                required: true,
            },
            image: {
                type: String,
                required: true,
            },
        },
        computed: {
            hartingLink() {
                return this.$routes('URL_HARTING_RAW', `${this.$env('languageShort')}${this.url}`);
            },
            teaserImage() {
                return `background-image: url('${this.image}');`
            },
        },
    };
</script>

<style scoped>

</style>