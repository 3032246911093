import to from 'await-to-js';
import { env, log } from '@/bootstrap';
import { domainConfigForLanguage, tryValue } from '@/libs/util';
import { waitForEvents, UNIQUE_REGISTRY, CONTACT_STORE_CREATED, USER_STORE_CREATED } from '@/events';

export const checkDomainEchatEnabled = () =>
    tryValue(domainConfigForLanguage(env('country')), 'domain_configuration.disable_echat', false) === false;

export const loadContact = async (vm) => {
    await waitForEvents([
        [UNIQUE_REGISTRY, CONTACT_STORE_CREATED, { timeout: 1000 }],
        [UNIQUE_REGISTRY, USER_STORE_CREATED, { timeout: 1000 }],
    ]);
    const { 'contact/contactPerson': contactPerson, 'user/isLoggedIn': isLoggedIn } = vm.$store.getters;

    if (contactPerson && contactPerson.name && !(isLoggedIn && contactPerson.isDefault)) {
        // Contact is set in cookie and not default for authenticated users
        return contactPerson;
    } else if ((isLoggedIn && (!contactPerson.name || contactPerson.isDefault)) || (!isLoggedIn && !contactPerson)) {
        // Authenticated user needs new contact or no data is found
        // --- Disabled ---
    }

    let contactId = undefined;
    const loadDrupalContact = () => vm.$store.dispatch('contact/requestContactPerson', { contactId });

    // Request contact person from drupal
    const [err1, contact] = await to(loadDrupalContact());
    if (err1) {
        throw err1;
    } else if (contact && contact.email && contact.phone) {
        return contact;
    } else if (!contactId) {
        log('Default contact is invalid', 'error', 'contact');
    }
     return contact;
};