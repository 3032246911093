<template>
    <div id="download-term-overlay-download-terms"
         :class="overlayClasses">
        <div class="download-term-overlay__box">
            <div class="download-term-overlay__head">
                <div class="download-term-overlay__headline">
                    {{ $t('title') }}
                </div>
            </div>

            <div class="download-term-overlay__body">
                <div class="download-term-overlay__content download-term-overlay__content--terms download-term-overlay__content--centered">
                    <input type="checkbox"
                           id="download-terms"
                           v-model="downloadTermsAccepted">

                    <label for="download-terms"
                           v-html="generalTermsLink"></label>
                </div>
            </div>

            <div class="download-term-overlay__footer">
                <a href="#"
                   class="button button--bg"
                   @click.prevent="close">
                    <span class="button__content">
                        {{ $t('cancel') }}
                    </span>
                </a>

                <a :class="downloadButtonClasses"
                   :disabled="!downloadTermsAccepted"
                   @click.prevent="dispatchDownload">
                    <span class="button__content">
                        {{ $t('download') }}
                    </span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import { COMMON_REGISTRY, UNIQUE_REGISTRY, LOGIN_FORM_TOGGLE, LOGIN_FORM_LOGGED_IN } from '@/events';
    import { env } from '@/bootstrap';
    import { download } from './lib';
    import { dom } from './config';

    export default {
        name: 'TermsDialog',
        data() {
            return {
                overlayVisible: false,
                downloadUrl: '',
                downloadTermsAccepted: false,
                removeDownloadListener: () => null,
            };
        },
        computed: {
            ...mapGetters('user', [
                'isLoggedIn',
            ]),
            ...mapGetters('cookies', [
                'termsAndConditions',
                'termsAndConditionsName',
            ]),
            generalTermsLink() {
                const hint = this.$t('please_accept');
                const link = this.$t('general_terms_url');
                const text = this.$t('general_terms');
                return hint.replace('{{link}}', `<a href="${link}">${text}</a>`);
            },
            overlayClasses() {
                return {
                    'download-term-overlay': true,
                    'download-term-overlay--opened': this.overlayVisible,
                };
            },
            downloadButtonClasses() {
                return {
                    'button': true,
                    'button--disabled': !this.downloadTermsAccepted,
                };
            },
        },
        methods: {
            ...mapActions('cookies', [
                'setCookie'
            ]),
            modifyDownloadLinkBehaviour() {
                const links = Array.from(document.querySelectorAll(`[${dom.dataDownload}]`));
                if (links.length === 0)
                    return;

                links.forEach(link => {
                    link.addEventListener('click', this.downloadLinkClickHandler);
                });
            },
            removeEventListeners() {
                const links = Array.from(document.querySelectorAll(`[${dom.dataDownload}]`));
                links.forEach(link => {
                    link.removeEventListener('click', this.downloadLinkClickHandler);
                });
            },
            downloadLinkClickHandler(event) {
                event.preventDefault();
                this.removeDownloadListener();
                const { currentTarget } = event;
                const downloadUrl = currentTarget.getAttribute(dom.dataDownload);
                this.downloadUrl = downloadUrl;

                if (this.isLoggedIn) {
                    // Download if user is logged in
                    download(downloadUrl);
                } else if (!env('isChina') && currentTarget.getAttribute('data-login-required') === 'true') {
                    // Download if user logs in (expect for china)
                    this.removeDownloadListener = this[UNIQUE_REGISTRY].on(LOGIN_FORM_LOGGED_IN, () => download(downloadUrl));
                    this[COMMON_REGISTRY].emit(LOGIN_FORM_TOGGLE, event);
                } else if (this.termsAndConditions) {
                    // Download if t&c got accepted
                    download(downloadUrl);
                } else {
                    // Show dialog
                    this.resetData();
                    this.overlayVisible = true;
                    window._uxa = window._uxa || [];
                    window._uxa.push(['setCustomVariable', 1, 'pagetype', 'widget']);
                    window._uxa.push(['setCustomVariable', 2, 'pagecategory1', 'TermsDialog']);
                    window._uxa.push(['setCustomVariable', 3, 'pagecategory2', '']);
                    window._uxa.push(['setCustomVariable', 4, 'pagecategory3', '']);
                    window._uxa.push(['setCustomVariable', 5, 'pagecategory4', '']);
                    window._uxa.push(['setCustomVariable', 6, 'pagecategory5', '']);
                    window._uxa.push(['setCustomVariable', 10, 'message', '']);
                    window._uxa.push(['trackPageview', window.location.pathname+window.location.hash.replace('#','?__')+'?cs-popin-downloadTerm']);
                }
            },
            dispatchDownload() {
                if (this.downloadTermsAccepted) {
                    download(this.downloadUrl);
                    this.close();
                    this.createTermsCookie();
                }
            },
            resetData() {
                this.downloadTermsAccepted = false;
            },
            close() {
                this.overlayVisible = false;
            },
            createTermsCookie() {
                this.setCookie({
                    name: this.termsAndConditionsName,
                    value: 'true',
                    args: {
                        expires: 1 / 48,
                    },
                });
            }
        },
        created() {
            // Reset cookie if found
            if (window.location.pathname.indexOf('downloadcenter') > -1 && this.termsAndConditions) {
                this.createTermsCookie();
            }
        },
        mounted() {
            this.modifyDownloadLinkBehaviour();
            window.reloadDataDownloadButtons = () => {
                this.removeEventListeners();
                this.modifyDownloadLinkBehaviour();
            };
        },
    };
</script>

<style scoped>

</style>