<template>
    <form v-show="!hidden"
          method="POST"
          @submit.prevent="login">
        <div :class="overlayClasses"
             @click.prevent="toggleDisplay"></div>

        <div class="popup popup--login">
            <div class="popup__header">
                <div class="popup-header__title">
                    myHARTING
                </div>

                <p class="popup-header__text"
                   v-html="myHartingHint"></p>
            </div>

            <div class="popup__body popup__text">
                <div class="popup__title login__headline">
                    {{ $t('title_login') }}
                </div>

                <div v-show="typeof error === 'string'"
                     class="notification notification--error">
                    <i class="notification__icon fa fa-exclamation-triangle"></i>
                    <span>{{ error }}</span>
                </div>

                <div v-show="loginEnabled">
                    <div class="input__form-group input__form-group--smaller-bottom-spacing">
                        <label for="login-form__username"
                               class="input__label">
                            {{ $t('input_email') }}
                        </label>

                        <input v-model="username"
                               type="text"
                               name="login"
                               id="login-form__username"
                               class="input__input"
                               autocomplete="username"
                               tabindex="1"/>
                    </div>

                    <div class="input__form-group">
                        <a :href="$routes('URL_PASSWORD_LOST')"
                           class="input__forgot-password"
                           target="_blank"
                           tabindex="5">
                            {{ $t('link_password_lost') }}
                        </a>

                        <label for="login-form-password"
                               class="input__label">
                            {{ $t('input_password') }}
                        </label>

                        <input v-model="password"
                               type="password"
                               name="password"
                               id="login-form-password"
                               class="input__input"
                               autocomplete="current-password"
                               tabindex="2"/>
                    </div>

                    <div class="input__form-group">
                        <button class="button button--full-width"
                                tabindex="3"
                                type="submit">
                            <div v-if="activeLogin"
                                 class="spinner spinner--no-spacing"
                                 disabled="disabled">&nbsp;
                            </div>

                            <span v-else
                                  class="button__content">
                                {{ $t('button_login') }}
                            </span>
                        </button>
                    </div>

                    <div class="popup__hint"
                         v-html="loginFormHelp"></div>
                </div>
            </div>

            <div class="popup__footer">
                <div class="popup__title login__headline">
                    {{ $t('title_register') }}
                </div>

                <div class="popup-footer__text popup__text">
                    {{ $t('text_register_description') }}
                </div>

                <div class="input__form-group">
                    <a :href="registerLink"
                       class="button button--full-width button--light button--vertically-spaced"
                       target="_blank">
                        <span class="button__content">
                            {{ $t('button_register') }}
                        </span>
                    </a>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
    import { mapActions } from 'vuex';
    import { COMMON_REGISTRY, LOGIN_FORM_TOGGLE } from '@/events';
    import authenticable from '@/libs/authenticable.mixin';
    import { dom } from './config';
    import { parseQueryParams } from '@/libs/util';
    import { loginMetaInformation } from '@/widgets/login/lib';

    export default {
        name: 'LoginForm',
        mixins: [authenticable],
        data() {
            return {
                hidden: true,
                registerParams: '',
            };
        }, 
        methods: {
            ...mapActions('ui', ['setBodyOverflowHidden']),
            toggleDisplay(event = null) {
                if (this.isLoggedIn) return;
                event && event.preventDefault();
                this.hidden = !this.hidden;
                this.setBodyOverflowHidden(!this.hidden);
                if (this.hidden) {
                    this.registerParams = '';
                } else if (event) {
                    const meta = loginMetaInformation(event);
                    this.registerParams = parseQueryParams(meta);
                }
                if (!this.hidden){
                    window._uxa = window._uxa || [];
                    window._uxa.push(['setCustomVariable', 1, 'pagetype', 'widget']);
                    window._uxa.push(['setCustomVariable', 2, 'pagecategory1', 'login']);
                    window._uxa.push(['setCustomVariable', 3, 'pagecategory2', '']);
                    window._uxa.push(['setCustomVariable', 4, 'pagecategory3', '']);
                    window._uxa.push(['setCustomVariable', 5, 'pagecategory4', '']);
                    window._uxa.push(['setCustomVariable', 6, 'pagecategory5', '']);
                    window._uxa.push(['setCustomVariable', 10, 'message', '']);
                    window._uxa.push(['trackPageview', window.location.pathname+window.location.hash.replace('#','?__')+'?cs-popin-login']);
                   
                }
                return false;
            },
        },
        computed: {
            myHartingHint() {
                const hint = this.$t('hint_myharting');
                const link = this.$t('hint_myharting_link');
                const text = this.$t('hint_myharting_text');
                return hint.replace('{{link}}', `<a href="${link}">${text}</a>`);
            },
            overlayClasses() {
                return {
                    'overlay': true,
                    'overlay--opened': !this.hidden,
                };
            },
            registerLink() {
                return this.$routes('URL_REGISTER_SERVICE_ACCOUNT', this.registerParams);
            }
        },
        async mounted() {
            // Register event listeners
            const loginButtonMobile = document.getElementById(dom.loginButtonMobileId);
            const loginButtonDesktop = document.getElementById(dom.loginButtonDesktopId);
            const logoutButton = document.getElementById(dom.menuLogoutId);
            this.eventListeners = [this[COMMON_REGISTRY].on(LOGIN_FORM_TOGGLE, this.toggleDisplay)];

            if (loginButtonMobile)
                this.eventListeners.push(this[COMMON_REGISTRY].native('click', this.toggleDisplay, loginButtonMobile));
            if (loginButtonDesktop)
                this.eventListeners.push(this[COMMON_REGISTRY].native('click', this.toggleDisplay, loginButtonDesktop));
            if (logoutButton)
                this.eventListeners.push(this[COMMON_REGISTRY].native('click', this.requestLogout, logoutButton));
            
        },
        beforeDestroy() {
            this.eventListeners.forEach(remove => typeof remove === 'function' && remove());
        },
    };
</script>
