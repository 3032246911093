import { log, createVue, createDOMHook } from '@/bootstrap';
import { checkDomainEchatEnabled } from './lib';
import Contact from './Contact';
import { dom } from './config';

const chatIsEnabled = checkDomainEchatEnabled()

createDOMHook({
    id: dom.hookId,
    classes: dom.hookClasses,
    container: dom.container,
});

const vm = chatIsEnabled ? createVue({
    component: Contact,
    element: `#${dom.hookId}`,
}) : null;



if (vm) {
    log('Widget initialized.', 'info', 'contact'); 
 } else {
    log('Vue initialization failed', 'error', 'contact')
}



