<template>
    <form v-show="!hidden"
          method="POST"
          @submit.prevent="loginAndNavigate">
        <div :class="overlayClasses"
             @click.prevent="toggleDisplay"></div>

        <div class="popup popup--configurator">
            <span class="popup-close"
                  @click.prevent="toggleDisplay">&times;</span>

            <div class="popup__header">
                <h3 class="popup-header__title">
                    {{ $t('configurator_title') }}
                </h3>

                <p class="popup-header__text">
                    {{ $t('configurator_text') }}
                </p>
            </div>

            <div class="popup__body popup__text">
                <div v-show="typeof error === 'string'"
                     class="notification notification--error">
                    <i class="notification__icon fa fa-exclamation-triangle"></i>
                    <span>{{ error }}</span>
                </div>

                <div v-show="loginEnabled">
                    <div class="input__form-group input__form-group--smaller-bottom-spacing">
                        <label for="configurator-form__username"
                               class="input__label">
                            {{ $t('input_email') }}
                        </label>

                        <input v-model="username"
                               type="text"
                               name="login"
                               id="configurator-form__username"
                               class="input__input"
                               autocomplete="username"
                               tabindex="1"/>
                    </div>

                    <div class="input__form-group">
                        <a :href="$routes('URL_PASSWORD_LOST')"
                           class="input__forgot-password"
                           target="_blank"
                           tabindex="4">
                            {{ $t('link_password_lost') }}
                        </a>

                        <label for="configurator-form__password"
                               class="input__label">
                            {{ $t('input_password') }}
                        </label>

                        <input v-model="password"
                               type="password"
                               name="password"
                               id="configurator-form__password"
                               class="input__input"
                               autocomplete="current-password"
                               tabindex="2"/>
                    </div>

                    <div class="input__form-group">
                        <button class="button button--full-width"
                                tabindex="3"
                                type="submit">
                            <div v-if="activeLogin"
                                 class="spinner spinner--no-spacing"
                                 disabled="disabled">&nbsp;
                            </div>

                            <span v-else
                                  class="button__content">
                                {{ $t('button_login') }}
                            </span>
                        </button>
                    </div>

                    <div class="popup__hint"
                         v-html="loginFormHelp"></div>
                </div>
            </div>

            <div class="popup__footer">
                <p class="popup-footer__text popup__text"
                   v-html="configuratorHint"></p>

                <button class="button button--light button--full-width button--vertically-spaced"
                        @click.prevent="navigateToLink">
                    {{ $t('button_continue_configurator') }}
                </button>
            </div>
        </div>
    </form>
</template>

<script>
    import { mapActions } from 'vuex';
    import { COMMON_REGISTRY, CONFIGURATOR_FORM_TOGGLE } from '@/events';
    import authenticable from '@/libs/authenticable.mixin';
    import { dom } from './config';

    export default {
        name: 'ConfiguratorLoginForm',
        mixins: [
            authenticable,
        ],
        data() {
            return {
                hidden: true,
                link: '',
                configuratorButtons: [],
            };
        },
        computed: {
            configuratorHint() {
                const hint = this.$t('hint_configurator');
                const link = this.$t('hint_configurator_link');
                const text = this.$t('hint_configurator_text');
                return hint.replace('{{link}}', `<a href="${link}" target="_blank">${text}</a>`);
            },
            overlayClasses() {
                return {
                    'overlay': true,
                    'overlay--opened': !this.hidden,
                };
            },
        },
        methods: {
            ...mapActions('ui', ['setBodyOverflowHidden']),
            toggleDisplay(event) {
                if (event) {
                    event.preventDefault();
                    this.link = event.currentTarget.href;
                }

                // Check if already logged in
                if (this.hidden && this.isLoggedIn) {
                    this.navigateToLink();
                    return false;
                }

                this.hidden = !this.hidden;
                this.setBodyOverflowHidden(!this.hidden);
                if (!this.hidden){
                    window._uxa = window._uxa || [];
                    window._uxa.push(['setCustomVariable', 1, 'pagetype', 'widget']);
                    window._uxa.push(['setCustomVariable', 2, 'pagecategory1', 'ConfiguratorLogin']);
                    window._uxa.push(['setCustomVariable', 3, 'pagecategory2', '']);
                    window._uxa.push(['setCustomVariable', 4, 'pagecategory3', '']);
                    window._uxa.push(['setCustomVariable', 5, 'pagecategory4', '']);
                    window._uxa.push(['setCustomVariable', 6, 'pagecategory5', '']);
                    window._uxa.push(['setCustomVariable', 10, 'message', '']);
                    window._uxa.push(['trackPageview', window.location.pathname+window.location.hash.replace('#','?__')+'?cs-popin-configuratorLogin']);
                }

                return this.hidden;
            },
            async loginAndNavigate() {
                const loggedIn = await this.login(false);
                if (!loggedIn) return false;
                this.navigateToLink();
            },
            navigateToLink() {
                if (typeof this.link === 'string' && this.link.length) {
                    window.location.href = this.link;
                }
            },
            fetchConfiguratorButtons() {
                this.configuratorButtons = Array.from(document.querySelectorAll(dom.configuratorLoginRequired));
            },
            addEventListeners() {
                this.configuratorButtons.forEach((button) => {
                    button.addEventListener('click', this.toggleDisplay);
                });
            },
            removeEventListeners() {
                this.configuratorButtons.forEach((button) => {
                    button.removeEventListener('click', this.toggleDisplay);
                });
            },
        },
        mounted() {
            this.eventListeners = [
                this[COMMON_REGISTRY].on(CONFIGURATOR_FORM_TOGGLE, () => {
                    this.toggleDisplay();
                })
            ];
            this.fetchConfiguratorButtons();
            this.addEventListeners();

            window.reloadConfiguratorLoginButtons = () => {
                this.removeEventListeners();
                this.fetchConfiguratorButtons();
                this.addEventListeners();
            };
        },
        beforeDestroy() {
            this.eventListeners.forEach(fn => fn());
            this.removeEventListeners();
        },
    };
</script>
