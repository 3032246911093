<template>
    <span>
        <!-- Input -->
        <div class="header-search__input-group">
            <form action="#"
                  method="post"
                  ref="form"
                  :class="formClasses"
                  :style="formStyle"
                  @submit.prevent="submitSearch">
                <input id="header-search__input"
                       class="header-search__input"
                       type="text"
                       v-model.lazy="searchTermModel"
                       :placeholder="$t('input_placeholder')"
                       @focus="openSearch">

                <button type="submit"
                        class="header-search__button"></button>
            </form>
        </div>

        <!-- Overlay -->
        <div class="header-search__overlay">
            <div class="header-search__back-button"
                 @click.prevent="closeSearch"></div>

            <div :class="resultsClasses">
                <div class="container">
                    <ul class="search-tabs"
                        ref="tabs">
                        <li class="search-tabs__item">
                            <a href="#"
                               ref="tabProducts"
                               :class="tabProductsClasses"
                               @click.prevent="() => onTabClick('products')">
                                {{ $t('products') }}
                            </a>
                        </li>

                        <li class="search-tabs__item">
                            <a href="#"
                               ref="tabArticles"
                               :class="tabArticlesClasses"
                               @click.prevent="() => onTabClick('articles')">
                                {{ $t('trends') }}
                            </a>
                        </li>
                    </ul>

                    <transition-group name="fade"
                                      is="div"
                                      class="search-tabs-content">
                        <!-- Products -->
                        <div v-if="showProductsTab"
                             class="search-tabs-content__item"
                             ref="products"
                             key="products"
                             :style="productContentStyles">
                            <div class="search-tabs-content__item-inner"
                                 :style="productInnerContentStyles">
                                <h3 class="header-search__title">
                                    {{ $t('products') }}
                                </h3>

                                <div class="product-category-search-result-teaser__product-categories">
                                    <div v-if="searchingProductCategories"
                                         class="spinner"></div>

                                    <div v-else-if="productCategoryCount === 0"
                                         class="product-category-search-result-teaser__error">
                                        {{ $t('no_matches_found') }}
                                        
                                        <div v-if="productCategorySuggestion">
                                            {{ $t('suggestion') }}
                                            
                                            <a href="#"
                                               @click.prevent="() => submitSearch(productCategorySuggestion)">
                                                {{ productCategorySuggestion }}
                                            </a>
                                        </div>
                                    </div>
                                    
                                    <ProductCategory v-else
                                                     v-for="productCategory in productCategories"
                                                     :title="productCategory.title"
                                                     :url="productCategory.url"
                                                     :result-amount="productCategory.resultAmount"
                                                     :subcategories="productCategory.subcategories"
                                                     :key="productCategory.id"/>
                                </div>
                            </div>
                        </div>

                        <!-- Articles -->
                        <div v-if="showArticlesTab"
                             class="search-tabs-content__item"
                             ref="articles"
                             key="articles"
                             :style="articleContentStyles">
                            <div class="search-tabs-content__item-inner"
                                 :style="articleInnerContentStyles">
                                <h3 class="header-search__title">
                                    {{ $t('trends') }}
                                </h3>

                                <div class="product-category-search-result-teaser__trends">
                                    <div v-if="searchingArticles"
                                         class="spinner"></div>

                                    <div v-else-if="articleCount === 0"
                                         class="product-category-search-result-teaser__error">
                                        {{ $t('no_matches_found') }}
                                    </div>

                                    <DrupalArticle v-else
                                                   v-for="article in articles"
                                                   v-bind="article"
                                                   :key="article.id"/>
                                </div>
                            </div>
                        </div>
                    </transition-group>
                </div>
            </div>
        </div>
    </span>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { dom } from './config';
    import DrupalArticle from './DrupalArticle';
    import ProductCategory from './ProductCategory';
    import { isValidSearchTerm } from '@/stores/search';

    export default {
        name: 'search',
        components: {
            DrupalArticle,
            ProductCategory,
        },
        data() {
            return {
                showOverlay: false,
                containerElement: null,
                activeTab: 'products',
                rtlTab: 'products',
                tabDirection: 0, // 0 = from left to right, 1 = from right to left
            };
        },
        computed: {
            ...mapGetters('ui', ['isMobile']),
            ...mapGetters('search', [
                'searchTerm',
                'redirect',
                'articles',
                'productCategories',
                'articleSuggestions',
                'productCategorySuggestion',
                'searchingArticles',
                'searchingProductCategories',
                'domainContentIsSearchable',
            ]),
            articleCount() {
                const articles = this.articles || [];
                return articles.length;
            },
            productCategoryCount() {
                const productCategories = this.productCategories || [];
                return productCategories.length;
            },
            showProductsTab() {
                if (!this.isMobile)
                    return true;
                return this.activeTab === 'products';
            },
            showArticlesTab() {
                if (!this.isMobile)
                    return true;
                return this.activeTab === 'articles';
            },
            formClasses() {
                return {
                    'header-search__form': true,
                };
            },
            formStyle() {
                if (this.showOverlay) {
                    const screenIsSmall = window.innerWidth < 800;
                    const { top } = this.$refs.form.getBoundingClientRect();
                    document.body.style['overflow-y'] = 'hidden';
                    return {
                        position: 'fixed',
                        top: `${top - window.scrollY}px`,
                        left: screenIsSmall ? '40%' : '50%',
                        width: screenIsSmall ? '50%' : '560px',
                        transform: 'translateX(-50%)',
                    };
                } else {
                    document.body.style['overflow-y'] = 'auto';
                    return {
                        position: 'static',
                        top: '',
                        left: '',
                        width: 'auto',
                        transform: '',
                    };
                }
            },
            resultsClasses() {
                return {
                    'header-search__results': true,
                    //'header-search__results--hide-drupal': this.domainContentIsSearchable,
                };
            },
            tabProductsClasses() {
                return {
                    'search-tabs__link': true,
                    'search-tabs__link--active': this.activeTab === 'products',
                    'search-tabs__link--rtl': this.rtlTab === 'products',
                };
            },
            tabArticlesClasses() {
                return {
                    'search-tabs__link': true,
                    'search-tabs__link--active': this.activeTab === 'articles',
                    'search-tabs__link--rtl': this.rtlTab === 'articles',
                };
            },
            productContentStyles() {
                return {};
            },
            productInnerContentStyles() {
                return {};
            },
            articleContentStyles() {
                return {};
            },
            articleInnerContentStyles() {
                return {};
            },
            searchTermModel: {
                get() {
                    return this.searchTerm;
                },
                set(value) {
                    if (value === '') {
                        this.closeSearch();
                    }
                    this.$store.dispatch('search/updateSearchTerm', value);
                }
            }
        },
        methods: {
            async submitSearch(searchTerm = null) {
                this.openSearch();
                if (isValidSearchTerm(searchTerm)) 
                    this.$store.commit('search/SET_STATE', { key: 'searchTerm', value: searchTerm });
                this.$store.dispatch('search/requestSearch');
            },
            openSearch() {
                if (!this.showOverlay){
                    window._uxa = window._uxa || [];
                    window._uxa.push(['setCustomVariable', 1, 'pagetype', 'widget']);
                    window._uxa.push(['setCustomVariable', 2, 'pagecategory1', 'search']);
                    window._uxa.push(['setCustomVariable', 3, 'pagecategory2', '']);
                    window._uxa.push(['setCustomVariable', 4, 'pagecategory3', '']);
                    window._uxa.push(['setCustomVariable', 5, 'pagecategory4', '']);
                    window._uxa.push(['setCustomVariable', 6, 'pagecategory5', '']);
                    window._uxa.push(['setCustomVariable', 10, 'message', '']);
                    window._uxa.push(['trackPageview', window.location.pathname+window.location.hash.replace('#','?__')+'?cs-popin-search']);
                 
                }
                this.showOverlay = true;
               
            },
            closeSearch() {
                this.showOverlay = false;
                this.$store.dispatch('search/updateSearchTerm', '');
            },
            onTabClick(name) {
                const previousTab = this.activeTab;
                this.activeTab = name;
                this.tabDirection = (name === 'products') ? 1 : 0;

                if (this.activeTab === name)
                    return;

                if (this.tabDirection === 0) {
                    // left to right
                    this.rtlTab = previousTab;
                } else if (this.tabDirection === 1) {
                    // right to left
                    this.rtlTab = name;
                }

                this.activeTab = name;

            },
        },
        watch: {
            // If showOverlay changes, classes have to be toggled outside of vue
            showOverlay(value) {
                if (this.containerElement) {
                    if (value) {
                        this.containerElement.classList.add(dom.openSearchModifier);
                    } else {
                        this.containerElement.classList.remove(dom.openSearchModifier);
                    }
                }
            },
        },
        created() {
            this.containerElement = document.querySelector(`#${dom.containerId}`);

            // Close on escape
            this.$events.native('keyup', event => {
                if (event.key === 'Escape') {
                    this.closeSearch();
                }
            });
        },
    };
</script>

<style scoped>
    .search-tabs-content__item {
        display: block;
    }

    .search-tabs-content__item-inner {
        display: block;
        opacity: 1;
    }

    .search-results--hide-drupal .search-tabs-content__item {
        flex-grow: 1;
    }

    .search-results--hide-drupal .search-tabs__item:last-of-type,
    .search-results--hide-drupal .search-tabs-content__item:last-of-type {
        display: none;
    }

    .fade-enter-active {
        transition: all .2s ease;
    }

    .fade-leave-active {
        transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }

    .fade-enter, .fade-leave-to {
        transform: translateX(10px);
        opacity: 0;
    }
</style>