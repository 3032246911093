import Cookies from 'js-cookie';
import { countryLanguageCookieName } from '@/libs/env';
import { dom } from './config';

export const attachMicrositeLinkClickHandler = () => {
    const link = document.querySelector(`.${dom.micrositeLinkClass}`);
    if (!link) return;
    
    link.addEventListener('click', () => {
        Cookies.remove(countryLanguageCookieName('drupal'));
        Cookies.remove(countryLanguageCookieName('hybris'));
    });
};