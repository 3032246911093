import Vuex from 'vuex';
import search from './search';
import user from './user';
import cookies from './cookies';
import contact from './contact';
import ui from './ui';


let store = null;

// Initializes singleton vuex store
const initStore = ({ Vue }) => {
    // Prevent multiple calls
    if (store)
        return store;

    Vue.use(Vuex);

    store = new Vuex.Store({
        modules: {
            user,
            search,
            cookies,
            contact,
            ui,
        },
        state: {},
        mutations: {},
        actions: {
            init({ dispatch }) {
                // Initialize stores 
                dispatch('cookies/init');
                dispatch('search/init');
                dispatch('ui/init');
                dispatch('user/init');
                dispatch('contact/init');
            },
        },
        getters: {}
    });

    // Init modules
    store.dispatch('init');

    // Attach getter to window
    window.getUserState = () => store.getters['user/userState'];

    return store;
};

export default initStore;
