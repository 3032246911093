/**
 * Bootstraps application environment for widgets
 */

import 'babel-polyfill';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import {
    initEvents,
    registries,
    COMMON_REGISTRY,
    UNIQUE_REGISTRY,
    ENV_CREATED,
    LANGUAGE_UPDATED,
} from '@/events';
import initStore from '@/stores';
import { env, initEnv, routes } from '@/libs/env';
import { createDOMHook } from '@/libs/util';
import { initLogger, log } from '@/libs/logger';

/*----------------------------------------------------------------------------------------------------------------------
 * Environment
 */


initEvents(Vue);
initEnv();
const isProduction = env('NODE_ENV') === 'production';
initLogger(isProduction);
registries(UNIQUE_REGISTRY).emit(ENV_CREATED);


if (!isProduction) {
    window.env = env;
}

window.routes = routes;
window.registries = registries;

export {
    env,
    routes,
    log,
    registries,
    createDOMHook,
};

/*----------------------------------------------------------------------------------------------------------------------
 * Vue & Plugins (Vuex, VueI18n)
 */

Vue.config.productionTip = false;
Vue.config.devtools = !isProduction;

// I18n
Vue.use(VueI18n);

// Bind env and routes to vue
Vue.prototype.$env = env;
Vue.prototype.$routes = routes;


let translations={};
const ms = Date.now();
fetch(routes('URL_DRUPAL_BASE','/sites/default/files/Translations.json?x='+ms), {credentials: 'include'})
        .then((response) => {
            if(response.ok){
                return response.json();
            }
            throw new Error('something is wrong');
        })
        .then(data=> {
            translations = data;
            registries(UNIQUE_REGISTRY).emit(LANGUAGE_UPDATED);
           
        })
        .catch((error) => {
            console.log(error);//maybe provide a fallback
        });
export {translations};
// Init store
const store = initStore({ Vue });

// Check screen resolution
const hamburger = document.querySelector('.header__menu-trigger');
const isMobile = hamburger && window.getComputedStyle(hamburger).display !== 'none';
store.dispatch('ui/setMobile', isMobile);

// Creates modular vue instance with component in hook element
export const createVue = ({
    component,
    element,
    props = {},
}) => {
    const opts = {}, { name: componentName } = component;

    // Check element
    if (!document.querySelector(element)) {
        log(`Element ${element} not found! Aborting mount of ${componentName}...`, 'error', 'vue');
        return null;
    }

    // Register translations
    const i18n = new VueI18n({
        locale: env('languageShort'),
        fallbackLocale: 'en',
        messages: translations[componentName],
    });

    // Bind props
    if (Object.keys(props).length > 0) {
        opts.props = props;
    }
    registries(UNIQUE_REGISTRY).on(LANGUAGE_UPDATED,()=>{
        if (translations[componentName]!==undefined){
            if (translations[componentName][env('languageShort')]!=undefined){
                i18n.setLocaleMessage(env('languageShort'),translations[componentName][env('languageShort')]);
            }else{
                i18n.setLocaleMessage(env('languageShort'),translations[componentName]['en']);
            }
        }
    });

    return new Vue({
        store,
        i18n,
        render: h => h(component, opts)
    }).$mount(element);
};

/*----------------------------------------------------------------------------------------------------------------------
 * Services
 */

const _services = {
    $env: env,
    $routes: routes,
    $store: store,
    [COMMON_REGISTRY]: registries(COMMON_REGISTRY),
    [UNIQUE_REGISTRY]: registries(UNIQUE_REGISTRY),
};
export const services = name => _services[name];

if (!isProduction) {
    window.services = services;
}

/*----------------------------------------------------------------------------------------------------------------------
 * Preparation & Setup
 */

// Fallback if wrapper for contact and scroll-indicator is missing
if (!document.getElementById('sticky-actions')) {
    createDOMHook({
        id: 'sticky-actions',
        classes: 'sticky-actions',
        container: '.footer',
    });
}

log('Completed bootstrapping.', 'info', 'vue');
