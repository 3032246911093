export const dom = {
    hookId: 'contact',
    hookClasses: 'contact',
    container: '#sticky-actions',
};

export const languageSwitchSelector = '.country-lang__btn'

// Countries that are enabled for the eChat. See in confluence: /pages/viewpage.action?pageId=35064308
export const validCountries = [
    'at',
    'au',
    'be',
    'cn',
    'cz',
    'de',
    'es',
    'gb',
    'hk',
    'in',
    'kr',
    'nl',
    'pt',
    'sg', // Attention: hybris writes "SG" in the cookie, even when SAP means "SGP".
    'tw',
    'uk',
    'us',
    'ru',
];

// Mapping is from "Country-SalesOrg Contacts.xslx" in confluence: /pages/viewpage.action?pageId=35064308
export const salesRegions = {
    AE: 'AE11',
    AM: 'DE81',
    AL: 'DE81',
    AR: 'BR11',
    AT: 'AT11',
    AU: 'AU11',
    AZ: 'DE81',
    BA: 'AT11',
    BE: 'BE11',
    BF: 'ZA11',
    BG: 'AT11',
    BH: 'AE11',
    BM: 'US11',
    BN: 'SG11',
    BR: 'BR11',
    BY: 'RU11',
    CA: 'CA11',
    CH: 'CH12',
    CL: 'BR11',
    CN: 'CN15',
    CO: 'BR11',
    CY: 'IT11',
    CZ: 'CZ11',
    DE: 'DE21',
    DK: 'DK11',
    EE: 'FI11',
    EG: 'AE11',
    ES: 'ES11',
    ET: 'ZA11',
    FI: 'FI11',
    FR: 'FR11',
    GB: 'GB11',
    GE: 'DE81',
    GR: 'IT11',
    HK: 'HK11',
    HR: 'AT11',
    HU: 'HU11',
    ID: 'SG11',
    IL: 'TR11',
    IN: 'IN11',
    IE: 'GB11',
    IR: 'AE11',
    IS: 'GB11',
    IT: 'IT11',
    JO: 'AE11',
    JP: 'JP11',
    KE: 'ZA11',
    KG: 'RU11',
    KR: 'KR11',
    KW: 'AE11',
    KZ: 'RU11',
    LB: 'AE11',
    LI: 'CH12',
    LK: 'DE41',
    LT: 'FI11',
    LU: 'BE11',
    LV: 'FI11',
    MA: 'DE41',
    MC: 'FR11',
    MD: 'RO12',
    ME: 'AT11',
    MG: 'AE11',
    MK: 'AT11',
    MO: 'HK11',
    MT: 'IT11',
    MX: 'US11',
    MY: 'SG11',
    NC: 'SG11',
    NL: 'NL11',
    NO: 'NO11',
    NZ: 'AU11',
    OM: 'AE11',
    PA: 'US11',
    PE: 'BR11',
    PH: 'SG11',
    PK: 'AE11',
    PL: 'PL11',
    PT: 'ES11',
    QA: 'AE11',
    RO: 'RO12',
    RU: 'RU11',
    SC: 'DE41',
    SE: 'SE11',
    SA: 'AE11',
    SG: 'SG11',
    SI: 'AT11',
    SK: 'CZ11',
    SM: 'IT11',
    SN: 'ZA11',
    TH: 'SG11',
    TJ: 'RU11',
    TM: 'RU11',
    TN: 'DE41',
    TR: 'TR11',
    TW: 'TW11',
    UA: 'PL11',
    UK: 'GB11',
    US: 'US11',
    UZ: 'RU11',
    VE: 'BR11',
    VG: 'GB11',
    VN: 'SG11',
    XK: 'AT11',
    XS: 'AT11',
    ZA: 'ZA11',
};

// Source: Kategorien.xlsx in confluence: /pages/viewpage.action?pageId=35064308
// Chin. traditionell: CN_zf
// Chin. simplified: CN_zh
// Commented keys are countries not currently supporting echat
export const echatCountryLanguages = {
    at_de: { cat: 'AT_Chat', lang: 'AT-DE' },
    at_en: { cat: 'AT_Chat', lang: 'AT-EN' },
    // be_fr: { cat: 'BE_Chat', lang: 'BE-FR' },
    // be_nl: { cat: 'BE_Chat', lang: 'BE-NL' },
    cn_en: { cat: 'CN_Chat', lang: 'CN-EN' },
    cn_zh: { cat: 'CN_Chat', lang: 'CN-ZH-HANS' },
    cn_zf: { cat: 'CN_Chat', lang: 'CN-ZH-HANT' },
    es_es: { cat: 'ES_Chat', lang: 'ES-ES' },
    es_en: { cat: 'ES_Chat', lang: 'ES-ES' },
    gb_en: { cat: 'GB_Chat', lang: 'GB-EN' },
    uk_en: { cat: 'GB_Chat', lang: 'GB-EN' },
    // nl_nl: { cat: 'NL_Chat', lang: 'NL-NL' },
    pt_en: { cat: 'PT_Chat', lang: 'PT-EN' },
    pt_es: { cat: 'PT_Chat', lang: 'PT-EN' },
    pt_pt: { cat: 'PT_Chat', lang: 'PT-ES' },
    pt_1p: { cat: 'PT_Chat', lang: 'PT-ES' },
    us_en: {
        cat: 'US_Chat', lang: 'US-EN',
        link: 'https://hartingusa.iceuc.com/iceMessaging/Login.html?dId=main&lang=en-CA',
    },
    in_en: { cat: 'IN_Chat', lang: 'IN-EN' },
    au_en: { cat: 'AU_Chat', lang: 'AU-EN' },
    sg_en: { cat: 'SGP_Chat', lang: 'SGP-EN' },
    hk_en: { cat: 'HK_Chat', lang: 'HK-EN' },
    hk_zh: { cat: 'HK_Chat', lang: 'HK-ZH-HANS' },
    hk_zf: { cat: 'HK_Chat', lang: 'HK-ZH-HANT' },
    tw_en: { cat: 'TW_Chat', lang: 'TW-EN' },
    tw_zf: { cat: 'TW_Chat', lang: 'TW-ZH-HANT' },
    de_de: { cat: 'DE_TechnicalSupport_Chat', lang: 'DE-DE_TechnicalSupport' },
    de_en: { cat: 'DE_TechnicalSupport_Chat', lang: 'DE-EN_TechnicalSupport' },
    cz_cs: { cat: 'CZ_Chat', lang: 'CZ-CS' },
    cz_en: { cat: 'CZ_Chat', lang: 'CZ-CS' },
    kr_ko: { cat: 'KR_Chat', lang: 'KR-KO' },
    ru_ru: { cat: 'RU_Chat', lang: 'RU-RU' },
    mx_en: {
        cat: 'MX_Chat', lang: 'MX-EN',
        link: 'https://hartingusa.iceuc.com/iceMessaging/Login.html?dId=main&lang=en-CA',
    },
    mx_es: {
        cat: 'MX_Chat', lang: 'MX-ES',
        link: 'https://hartingusa.iceuc.com/iceMessaging/Login.html?dId=main&lang=es-MX',
    },
    mx_1x: {
        cat: 'MX_Chat', lang: 'MX-ES',
        link: 'https://hartingusa.iceuc.com/iceMessaging/Login.html?dId=main&lang=es-MX',
    },
    ca_en: {
        cat: 'MX_Chat', lang: 'MX-ES',
        link: 'https://hartingusa.iceuc.com/iceMessaging/Login.html?dId=main&lang=en-CA',
    },
};