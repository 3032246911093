import Vue from 'vue';
import to from 'await-to-js';
import { env, log, routes } from '@/bootstrap';
import { checkDomainEchatEnabled } from '@/widgets/contact/lib';
import { createRequestAdapter } from '@/libs/adapter';
import { checkType } from '@/libs/util';
import { transformContactPerson } from '@/libs/handler';
import {
    registries,
    waitForEvents,
    UNIQUE_REGISTRY,
    CONTACT_STORE_CREATED,
    UI_STORE_CREATED,
    COOKIE_STORE_CREATED
} from '@/events';

export default {
    namespaced: true,
    state: {
        httpContact: null,
        image: null,
        name: null,
        email: null,
        phone: null,
        isDefault: null,
        chatIsEnabled: true,
    },
    mutations: {
        SET_STATE(state, { key, value }) {
            Vue.set(state, key, value);
        },
        SET_CONTACT_PERSON(state, contactPerson) {
            const { name, email, phone, image, isDefault } = contactPerson;
            state.name = name;
            state.email = email;
            state.phone = phone;
            state.image = image;
            state.isDefault = isDefault;
        }
    },
    actions: {
        async init({ commit, dispatch, getters }) {
            commit('SET_STATE', {
                key: 'httpContact',
                value: createRequestAdapter({
                    url: routes('URL_DRUPAL_CONTACT'),
                    auth: {
                        username: env('HTTP_AUTH_WEBSITE_USERNAME'),
                        password: env('HTTP_AUTH_WEBSITE_PASSWORD'),
                    },
                })
            });

            const chatIsEnabled = checkDomainEchatEnabled()
            commit('SET_STATE', { key: 'chatIsEnabled', value: chatIsEnabled });

            // Wait until cookie store is created
            await waitForEvents([
                [UNIQUE_REGISTRY, UI_STORE_CREATED, { timeout: 1000 }],
                [UNIQUE_REGISTRY, COOKIE_STORE_CREATED, { timeout: 1000 }],
            ]);

            if (chatIsEnabled) {
                dispatch('loadCookie');
            }
            registries(UNIQUE_REGISTRY).emit(CONTACT_STORE_CREATED, getters);
        },
        loadCookie({ commit, rootGetters }) {
            const cookie = rootGetters['cookies/contactPerson'];
            const countryLanguage = rootGetters['cookies/countryLanguage'];
            let contactPerson = null;

            if (cookie) {
                try {
                    contactPerson = JSON.parse(cookie);

                    if (!contactPerson) {
                        log('loadContactPerson: Invalid contact info.', 'error', 'contact');
                        return null;
                    }

                    // HARTHDBP-748 The cookie is only valid if the country/language match.
                    if (!contactPerson.countryLanguage) {
                        log('loadContactPerson: Country/language info missing.', 'error', 'contact');
                        return null;
                    }

                    if (contactPerson.countryLanguage !== countryLanguage) {
                        log(`loadContactPerson: Country/language mismatch: "${contactPerson.countryLanguage}" != "${countryLanguage}"`, 'error', 'contact');
                        return null;
                    }

                    commit('SET_CONTACT_PERSON', contactPerson);
                } catch (o_o) {
                    log(o_o, 'error', 'contact');
                }
            }

            return contactPerson;
        },
        saveCookie({ state, dispatch, rootGetters }) {
            const isValid = checkType(state, {
                subsidiary: 'string',
                email: 'string',
                phone: 'string',
            });
            if (!isValid) return false;

            const name = rootGetters['cookies/contactPersonName'];
            const cookie = {
                name: state.subsidiary,
                email: state.email,
                phone: state.phone,
                image: env('URL_DRUPAL_BASE')+'/sites/default/files/styles/meta_contact_person_image/public/2017-12/HARTING_Avatar_Male.jpg',
                isDefault: state.isDefault,
            };
            dispatch('cookies/setCookie', {
                name,
                value: JSON.stringify(cookie),
                args: {
                    expires: 1,
                }
            }, { root: true });
        },
        async requestContactPerson({ state, commit }, { contactId }) {
            await registries(UNIQUE_REGISTRY).wait(CONTACT_STORE_CREATED, { timeout: 1000 });
            if (!state.httpContact) throw 'Contact api adapter is not defined';

            const required = {
                subsidiary: 'string',
                email: 'any',
                phone: 'any',
            };
            const queryParams = {};
            

            if (contactId) {
                queryParams.contact_id = contactId;
            } else {
                queryParams.default_contact = 1;
            }

            const [err, res] = await to(state.httpContact.get());
            if (err) {
                throw err;
            } else if (!res || !res.data || res.data.length === 0) {
                throw 'Response from drupal is undefined';
            }

            let contact = res.data;
            if (!checkType(contact, required)) {
                throw 'Response from drupal has invalid structure';
            } else if (queryParams.default_contact === 1) {
                contact.is_default = true;
            }

            contact = transformContactPerson(contact);
            commit('SET_CONTACT_PERSON', contact);

            return contact;
        },
    },
    getters: {
        image: state => state.image,
        name: state => state.name,
        email: state => state.email,
        phone: state => state.phone,
        isDefault: state => state.isDefault,
        contactPerson: state => ({
            image: state.image,
            name: state.name,
            email: state.email,
            phone: state.phone,
            isDefault: state.isDefault,
        }),
        countryLanguage: state => env('country').toLowerCase() + '-' + env('languageShort').toLowerCase(),
        chatIsEnabled: state => state.chatIsEnabled,
    }
};
