import VueEventRegistry from 'vue-event-registry';

/*
 * Registry
 */
const _registries = {};
export const COMMON_REGISTRY = '$events';
export const UNIQUE_REGISTRY = '$unique';
export const registries = (name) => name ? _registries[name] : _registries;
export const initEvents = (Vue) => {
    // Create event registry
    Vue.use(VueEventRegistry, {
        name: COMMON_REGISTRY,
        uniqueName: UNIQUE_REGISTRY,
    });
    _registries[COMMON_REGISTRY] = Vue.prototype[COMMON_REGISTRY];
    _registries[UNIQUE_REGISTRY] = Vue.prototype[UNIQUE_REGISTRY];
    registries(COMMON_REGISTRY).native('click', event => registries(COMMON_REGISTRY).emit(NATIVE_CLICK, event));
};

// Returns promise to wait for registry events
// Pass an array (of arrays) containing items like: [$registry, $event, $config]
// Example: ['$unique', 'user-store:created', { timeout: 1000 }]
export const waitForEvents = (events = []) => {
    const mappedEvents = events.map(([registry, event, config]) => registries(registry).wait(event, config));
    return Promise.all(mappedEvents);
};

/*
 * Unique Events
 */
export const ENV_CREATED = 'env:created';
export const USER_STORE_CREATED = 'user-store:created';
export const COOKIE_STORE_CREATED = 'cookie-store:created';
export const CONTACT_STORE_CREATED = 'contact-store:created';
export const SEARCH_STORE_CREATED = 'search-store:created';
export const UI_STORE_CREATED = 'ui-store:created';
export const LANGUAGE_CREATED = 'language:created';
export const SESSION_CREATED = 'session:created';
export const LOGIN_FORM_LOGGED_IN = 'login-form:login';

/*
 * Common Events
 */
export const NATIVE_CLICK = 'native:click';
export const CLOCK_TICK = 'clock:tick';
export const SESSION_UPDATED = 'session:updated';
export const LANGUAGE_UPDATED = 'language:updated';
export const DOMAINS_UPDATE = 'domains:update';
export const NEWSLETTER_TOGGLE = 'newsletter:toggle';
export const LOGIN_FORM_TOGGLE = 'login-form:toggle';
export const CONFIGURATOR_FORM_TOGGLE = 'configurator-form:toggle';
export const LOGIN_PROVIDER_LOGIN_AT_IP = 'login-provider:loginAtIp';
export const LOGIN_PROVIDER_GET_USER_DATA = 'login-provider:getUserData';
export const LOGIN_PROVIDER_GET_HYBRIS_COUNTRY = 'login-provider:getHybrisCountry';
//export const LOGIN_PROVIDER_LOGIN_AT_IP = 'login-provider:login-at-ip';
//export const LOGIN_PROVIDER_GET_USER_DATA = 'login-provider:get-user-data';
//export const LOGIN_PROVIDER_GET_HYBRIS_COUNTRY = 'login-provider:get-hybris-country';
