<template>
<div :class="contactClasses"    ref="contact">
    <div :class="innerClasses">
        <template v-if="displayContactInfo">
            <div class="contact__pane contact__pane--person">
                <div class="contact__avatar">
                    <img :src="image" alt=""  class="contact__avatar-image" />
                </div>
                <div class="contact__text">
                    {{ $t('your_contact') }}
                </div>

                <div class="contact__name">
                    {{$t('customer_service') }}
                </div>
            </div>

            <div class="contact__pane">
                <a class="contact__email"  :href="'mailto:' + email">
                   {{ email }}
                </a>
                <a v-if="phone"  class="contact__phone"    :href="'tel:' + phone">
                    {{ phone }}
                </a>
            </div>

            <div v-if="chatLink" class="contact__pane">
                <div class="contact__text contact__text--more-spacing">
                    {{ $t('go_to_general_chat') }}
                </div>

                <a :href="chatLink" target="_blank" class="button button--full-width">
                    <span class="button__content">
                        {{$t('HARTING_eChat')}}
                    </span>
                </a>
            </div>
        </template>

        <div v-else  class="contact__pane contact__pane--not-found">
            To get in touch with HARTING please choose your
            <a href="#"  @click.prevent="openLanguagePicker">
                        country and language
            </a>.
        </div>
    </div>
</div>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex';
    import { env, log } from '@/bootstrap';
    import { languageSwitchSelector, echatCountryLanguages } from './config';
    import { simulateClick } from '@/libs/util';
    import {loadContact } from './lib';
    export default {
        name: 'ContactComponent',
        props: {
            endReached: Boolean
        },
        data() {
            return {
                displayContactInfo: true,
                isLoaded: false,
            };
        },
        computed: {
            ...mapGetters('contact', [
                'image',
                'name',
                'email',
                'phone',
            ]),
            contactClasses() {
                return {
                    'contact': true,
                    'contact--open': true,
                    'contact--end-reached': this.endReached,
                };
            },
            innerClasses() {
                return {
                    'contact__inner': true,
                    'contact__inner--open': this.isOpen,
                };
            },
            chatLink() {
                const { country, languageShort } = env();
                if (!country || !languageShort) {
                    return false;
                }
                const urlSuffix = country === 'de' ? '_de' : '';
                const key = `${country.toLowerCase()}_${languageShort}`;
                if (!(key in echatCountryLanguages)) {
                    log(`generateChatButtonLink: No mapping for key "${key}" found.`, 'error', 'contact');
                    return false;
                }

                const { cat, lang, link } = echatCountryLanguages[key];
                if (link) {
                    return link;
                }
                if (env('isChina')) {
                    return `https://b2b.harting.com.cn/b2bchat/next_cockpit_harting${urlSuffix}/popup.html?lang=${lang}&cat=${cat}`;
                }else{
                    return `https://b2b.harting.com/b2bchat/next_cockpit_harting${urlSuffix}/popup.html?lang=${lang}&cat=${cat}`;
                }
            }
        },
        methods: {
            ...mapActions('contact', []),
             openLanguagePicker() {
                const languageSwitch = document.querySelector(languageSwitchSelector);
                if (languageSwitch) {
                    simulateClick(languageSwitch);
                }
            },
           
        },
        mounted() {
            loadContact(this).then(contact => {
                log(`Contact person: ${JSON.stringify(contact)}`, 'info', 'contact');
            })
            .catch(err => log(err, 'error', 'contact'))
            .finally(() => {
                log('Widget initialized.', 'info', 'contact');
            });
        }
    };
      
</script>