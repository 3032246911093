<script>
    import { log } from '@/bootstrap';
    import { createRequestAdapter } from '@/libs/adapter';

    export default {
        name: 'LoginProvider',
        render() {
            // This component has only functionality and no markup
            return '';
        },
        methods: {
            // TODO: Refactor to async 
            loginAtIp(url, login, password) {
                const adapter = createRequestAdapter({ url });
                const data = { login, password, language: this.$env('languageShort') };

                return new Promise((resolve, reject) => {
                    adapter
                        .post({ data })
                        .then(response => {
                            log('Requesting login from identity provider', 'info', 'login');
                            if (response &&
                                response.data &&
                                response.data.data &&
                                response.data.data.token) {
                                // Authenticated
                                resolve(response.data.data);
                            } else {
                                // No token => Invalid response
                                log('loginAtIp: Response is invalid', 'info', 'login');
                                reject({ message: 'Technical error: The identity provider server answered without a valid token.' });
                            }
                        })
                        .catch(error => {
                            if (error.response) {
                                if (error.response.data &&
                                    error.response.data.error === 'Invalid credentials') {
                                    // Access denied
                                    log('loginAtIp: Access denied.', 'error', 'login');
                                    reject({ message: 'Access denied.' });
                                } else {
                                    // Error message
                                    log('loginAtIp: Login error', 'error', 'login');
                                    reject({ message: error.response.data.error });
                                }
                            } else {
                                // WTF
                                log('loginAtIp: Unknown error', 'error', 'login');
                                reject({ message: 'Unknown error' });
                            }
                        });
                });
            },
            // TODO: Refactor to async 
            getUserData(url, token) {
                const adapter = createRequestAdapter({ url });
                const data = { type: 'session', data: { token } };

                return new Promise((resolve, reject) => {
                    adapter
                        .post({ data })
                        .then(response => {
                            log('getUserData', response, 'login');
                            if (response && response.data && response.data.data) {
                                resolve(response.data.data);
                            } else {
                                log('getUserData: User data response is invalid', response, 'login');
                                reject({ message: 'Technical error: Getting the user data failed.' });
                            }
                        })
                        .catch(error => {
                            log('getUserData: Unknown error', error, 'login');
                            reject({ message: `Unknown error: ${error.response}` });
                        });
                });
            },
            // TODO: Refactor to request adapter
            getHybrisCountry(url, auth) {
                let useAuth = true;
                if (!auth) {
                    useAuth = false;
                    auth = {
                        username: null,
                        password: null,
                    };
                }
                return new Promise((resolve, reject) => {
                    const XMLReq = new XMLHttpRequest();

                    XMLReq.onreadystatechange = function () {
                        if (this.readyState == 4 && this.status == 200) {
                            resolve(this.responseText);
                        }
                    };

                    XMLReq.open('GET', url, true /*, auth.username, auth.password*/);
                    if (useAuth) {
                        XMLReq.setRequestHeader('Authorization', 'Basic ' + btoa(`${auth.username}:${auth.password}`));
                    }
                    XMLReq.send(null);
                });
            }
        },
        created() {
            window.loginAtIp = this.loginAtIp;
            window.getUserData = this.getUserData;
            window.getHybrisCountry = this.getHybrisCountry;
        },
    };
</script>

<style scoped>

</style>