import { createVue, createDOMHook, log } from '@/bootstrap';
import ScrollIndicator from './ScrollIndicator';
import { dom } from './config';

createDOMHook({
    id: dom.hookId,
    container: dom.container,
});

createVue({
    component: ScrollIndicator,
    element: `#${dom.hookId}`,
});

log('Widget initialized.', 'info', 'scroll-indicator');