/**
 * util.js
 *
 * Utility functions
 */

import domainConfig from '@/../assets/domain-configuration';
import { log } from './logger';

const PRODUCTION = process.env.NODE_ENV === 'production';

// Gets called as default parameter and throws an error because required parameter is missing
export const isRequired = name => {
    throw new Error(`Parameter ${name} is required!`);
};

// Follows path of keys inside obj
// Returns value if found and fallback if not
export const tryValue = (obj = {}, path = [], fallback = null) => {
    let pointer = obj;
    if (obj === null || typeof obj !== 'object') {
        return fallback;
    }
    
    if (typeof path === 'string') {
        path = path.split('.')
    }

    for (const key of path) {
        const val = pointer[key];
        if (typeof val !== 'undefined') {
            if (typeof val === 'object') {
                // Follow path deeper into object
                pointer = val;
            } else {
                // Return found value
                return val;
            }
        } else {
            // Key from path not found, return fallback
            return typeof fallback === 'function' ? fallback() : fallback;
        }
    }

    return pointer;
};

// Finds domain config for given country code
export const domainConfigForLanguage = countryCode => {
    const country = countryCode.toUpperCase();
    return domainConfig.filter(c => c.domain_path.toUpperCase() === country)[0];
};

// Reduce function for objects
export const reduceObject = (obj, callback, accumulator) => {
    for (const key in obj) {
        if (!obj.hasOwnProperty(key)) 
            continue;
        const val = obj[key];
        accumulator = callback(accumulator, key, val);
    }
    return accumulator;
};

// Creates HTML element and appends it to DOM
// Vue instances will be mounted on these, to prevent interference with rest of DOM
export const createDOMHook = ({
    id,
    tag = 'div',
    classes = '',
    container = null,
}) => {
    const hook = document.createElement(tag);
    hook.id = id;

    if (classes !== '')
        hook.className = classes;

    const node = (typeof container === 'string') ?
        document.querySelector(container) :
        document.body;
    if (node) {
        node.appendChild(hook);
    } else {
        log(`Could not find element with selector "${container}"`, 'error', 'vue');
    }
};

// Shortcut to get pretty printed json from given object
export const prettyPrint = obj => JSON.stringify(obj, undefined, 2);

// Encodes and joins query param hash
export const parseQueryParams = params => {
    const queries = [];

    for (let key of Object.keys(params)) {
        queries.push(
            `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
        );
    }

    if (queries.length > 0) {
        return '?' + queries.join('&');
    } else {
        return '';
    }
};

// Checks given input and returns true if it is valid
export const validateInput = input => {
    if (!input) {
        console.error(`[Util.validateInput] Input does not exist!`);
        return false;
    }

    // TODO: validation

    return true;
};

// Checks given input and returns true if it is valid
export const validateForm = form => {
    if (!form) {
        console.error(`[Util.validateForm] Form does not exist!`);
        return false;
    }

    // TODO: validation

    return true;
};

// Returns object which handles printing into dom element
export const createOutput = (domElement, template = null) => ({
    print(text, mode = '', wrapInPre = true) {
        let className;
        switch (mode) {
            case 'success':
                className = ' class="output--success"';
                break;
            case 'error':
                className = ' class="output--error"';
                break;
            default:
                className = '';
        }

        if (wrapInPre) {
            text = `<pre${className}>${text}</pre>`;
        }

        if (domElement) {
            domElement.innerHTML += text;
        }
    },
    clear() {
        if (domElement) {
            domElement.innerHTML = '';
        }
    },
    render(data) {
        if (typeof template === 'function') {
            if (domElement) {
                domElement.innerHTML = template(data);
            }
        } else if (typeof template === `string`) {
            if (domElement) {
                domElement.innerHTML = template;
            }
        }
    },
});

// Returns simple timer to measure code execution
export const createTimer = () => {
    let now;

    if (window.performance !== undefined) {
        now = () => window.performance.now();
    } else {
        now = () => new Date().getTime();
    }

    return {
        now,
        startTime: null,
        start() {
            this.startTime = this.now();
        },
        time(unit = 'ms', decimal = 4) {
            const time = this.now() - this.startTime;

            switch (unit) {
                case 's':
                    return parseFloat((time / 1000).toFixed(decimal));
                case 'ms':
                default:
                    return parseFloat(time.toFixed(decimal));
            }
        },
    };
};

// Sets styles for given element
export const css = (element, styles) => {
    for (let prop in styles) {
        element.style[prop] = styles[prop];
    }
};

// Logger which is partially disabled in production and usable in test
export const createLogger = () => ({
    log(...data) {
        console.log(...data);
    },
    error(...data) {
        console.error(...data);
    },
    debug(...data) {
        if (!PRODUCTION) {
            if (process.env.NODE_ENV !== 'test') {
                console.debug(...data);
            } else {
                console.log(...data);
            }
        }
    },
    warn(...data) {
        if (!PRODUCTION) {
            if (process.env.NODE_ENV !== 'test') {
                console.warn(...data);
            } else {
                console.log(...data);
            }
        }
    },
});

// Returns style object based on computed styles of given element or initial value
export const getStyleObject = (element, styleList) => {
    const computedStyles = window.getComputedStyle(element, null);

    // Create object with keys from styleList mapped to values from computedStyles
    return Array.from(styleList).reduce((obj, attr) => {
        const style = computedStyles.getPropertyValue(attr);
        obj[attr] = style !== '' ? style : 'initial';
        return obj;
    }, {});
};

// Calculates height of element without the user seeing it
export const getHeight = element => {
    let elemStyle = window.getComputedStyle(element, null),
        elemDisplay = elemStyle.display,
        elemPosition = elemStyle.position,
        elemVisibility = elemStyle.visibility,
        elemMaxHeight = elemStyle.maxHeight.replace('px', '').replace('%', ''),
        height;

    // if its not hidden we just return normal height
    if (elemDisplay !== 'none' && elemMaxHeight !== '0') {
        return element.offsetHeight;
    }

    // Measure height of hidden element
    element.style.position = 'absolute';
    element.style.visibility = 'hidden';
    element.style.display = 'block';

    height = element.offsetHeight;

    // Reverting to the original values
    element.style.display = elemDisplay;
    element.style.position = elemPosition;
    element.style.visibility = elemVisibility;

    return height;
};

// Sets transition and height
const slideAnimation = (element, finalHeight, duration) => {
    const prevStyles = getStyleObject(element, [`transition`, `height`]);

    // Animation
    css(element, {
        transition: `height ${duration / 1000.0}s`,
        height: finalHeight + 'px',
    });

    return new Promise((resolve, reject) => {
        setTimeout(() => {
            // Hide element if height is zero or else show it
            if (finalHeight === 0) {
                prevStyles.display = 'none';
            } else {
                prevStyles.display = 'block';
            }

            // Apply styles on completion
            css(element, prevStyles);

            resolve();
        }, duration);
    });
};

// Wrapper for custom slide function which behaves like jQuery.slideUp or jQuery.slideDown
export const slide = (
    element = isRequired('element'),
    direction = isRequired('direction'),
    opts = {}
) => {
    let maxHeight = element.getAttribute('data-max-height');

    // Calc max height
    if (!maxHeight) {
        maxHeight = getHeight(element);
    }

    // Set duration default if not given
    if (!opts.duration) {
        opts.duration = 500;
    }

    // Call begin callback if given
    if (typeof opts.begin === 'function') {
        opts.begin();
    }

    // Create arguments for animation function
    let animArgs;
    if (direction === 'up') {
        animArgs = [element, 0, opts.duration];
    } else if (direction === 'down') {
        animArgs = [element, maxHeight, opts.duration];
    } else {
        throw new Error(`Direction ${direction} does not exist`);
    }

    // Perform slide animation
    slideAnimation(...animArgs)
        .then(() => {
            // Call complete callback if given
            if (typeof opts.complete === 'function') {
                opts.complete();
            }
        })
        .catch(console.error);
};

// Wrapper for Promise.all() which waits for every promise to settle
export const promiseWaitForAll = promises => {
    const mappedPromises = Array.from(promises).map(p => {
        if (typeof p !== 'undefined' && typeof p.catch === 'function') {
            return p.catch(e => e);
        } else {
            return p;
        }
    });

    return Promise.all(mappedPromises);
};

// Checks if properties are present in collection and of the correct type
// Example:
//      prop1: 'string',    -> checks if type of prop1 is string
//      prop2: 'any',       -> checks if prop2 is present and not null
//      prop3: 'number?',   -> checks if prop3 is number, but only if its present
export const checkType = (properties = {}, types = {}) => {
    for (const prop in types) {
        let type = types[prop];
        const value = properties[prop];
        const isOptional = /\?$/.test(type);
        const ignoreType = /^any\??$/.test(type);

        type = type.replace(`?`, ``);

        // Fail if
        // - prop is required and not present
        // - type is required and does not match
        if ((!isOptional && !properties.hasOwnProperty(prop)) ||
            (!ignoreType && typeof value !== type)) {
            return false;
        }
    }

    return true;
};

// Stops execution until given event was emitted
export const waitForEvent = (name, node, timeout = 10000) => {
    let hasFinished = false,
        hasTimedOut = false;

    setTimeout(() => {
        if (!hasFinished) {
            console.error(`waitForEvent() timed out!`);
            hasTimedOut = true;
        }
    }, timeout);

    return new Promise((resolve, reject) => {
        const eventHandler = evt => {
            node.removeEventListener(name, eventHandler);
            hasFinished = true;

            if (hasTimedOut) {
                console.log(`Timeout before event has fired!`);
                reject(evt);
            } else {
                console.log(`Done waiting for "${name}" event!`);
                resolve(evt);
            }
        };

        node.addEventListener(name, eventHandler);
    }).catch(console.error);
};

export const simulateClick = (target) => {
    const event = document.createEvent('Event');
    event.initEvent('click', true, true);
    target.dispatchEvent(event);
};

// Returns current unix time in seconds
export const timestampSeconds = () => parseInt(new Date().getTime() / 1000);

// Returns max amount of days for cookies to not expire (=> end of 32-bit era)
export const maxCookieAge = () =>
    Math.floor((new Date(2038, 0, 0) - new Date()) / (1000 * 3600 * 24));

// Returns text translated with translation object
export const translateText = (text, translations) => Object.entries(translations)
    .reduce((t, [k, v]) => t.replace(`{{${k}}}`, v), text)