import to from 'await-to-js';
import { mapGetters, mapActions } from 'vuex';
import { COMMON_REGISTRY, UNIQUE_REGISTRY, LOGIN_FORM_LOGGED_IN } from '../events';
import { tryValue } from './util';

export default {
    data() {
        return {
            activeLogin: false,
            loginEnabled: true,
            username: '',
            password: '',
            error: null,
        };
    },
    mounted() {
        this.checkLoginState();
    },
    computed: {
        ...mapGetters('user', [
            'userId',
            'isLoggedIn',
        ]),
        loginFormHelp() {
            const hint = this.$t('hint_loginissues');
            const link = this.$t('hint_loginissues_link');
            const text = this.$t('hint_loginissues_text');
            return hint.replace('{{link}}', `<a href="${link}">${text}</a>`);
        },
    },
    methods: {
        ...mapActions('user', [
            'requestLogin',
            'requestLoginState',
            'requestLogout',
        ]),
        // Request login state from login form api
        async checkLoginState() {
            const [loginStateError, loginEnabled] = await to(this.requestLoginState());
            this.loginEnabled = loginEnabled;

            if (loginStateError) {
                this.handleRequestException(loginStateError);
                return false;
            }

            if (!loginEnabled) {
                this.error = this.$t('no_stock_check');
            }

            return loginEnabled;
        },
        // Authenticates user, returns bool whether it was successful
        async login(reload = true) {
            if (this.activeLogin)
                return false;

            const { username, password } = this;
            this.activeLogin = true;

            const login = this.requestLogin({
                username,
                password,
            });
            const [loginError, loginResponse] = await to(login);
            if (loginError) {
                this.handleRequestException(loginError);
                this.activeLogin = false;
                return false;
            }

            this[UNIQUE_REGISTRY].emit(LOGIN_FORM_LOGGED_IN);
            if (reload) {
                location.reload();
            }

            return true;
        },
        handleRequestException(e) {
            const msg = tryValue(e, ['response', 'data', 'error']);
            this.error = msg ? `${msg}` : this.$t('placeholder_error');

            if (typeof this.onLoginFailure === 'function') {
                this.onLoginFailure();
            }
        },
        validateResponse(response) {
            return (
                response &&
                response.data !== undefined &&
                response.data.data !== undefined &&
                response.data.data.token !== undefined
            );
        },
    },
};
