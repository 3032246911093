/**
 * adapter.js
 *
 * It is responsible for fetching and returning data from an api endpoint
 *
 *  Usage requestAdapter:
 *      const adapter = createAdapter({...});
 *      const apiData = adapter.get({...});
 */

import axios from 'axios';

// Factory function which returns a wrapper for axios instance
export const createRequestAdapter = ({
    url = '',
    auth = null,
    axiosAdapter = null,
    debug = false,
}) => {
    const request = ({
        method = 'GET',
        path = '',
        route = null,
        params = {},
        data = null,
    }) => {
        // Use given route or create one from url and path
        if (!route) {
            route = url + path;
        }

        const baseParameters = {
            timeout: 30000,
            headers: method === 'POST' ? { 'Content-Type': 'application/json' } : {},
        };

        // Authentication
        if (auth && auth.username && auth.password) {
            baseParameters.auth = auth;
            baseParameters.withCredentials = true;
        }

        // Adapter injection if XMLHttpRequest is not available
        if (axiosAdapter) {
            baseParameters.adapter = axiosAdapter;
        }

        const axiosInstance = axios.create(baseParameters);

        if (debug) {
            console.log('Axios >>>', method, route, params, data);
        }

        if (method === 'GET') {
            return axiosInstance.get(route, { params });
        } else if (method === 'POST') {
            return axiosInstance.post(route, data);
        } else if (method === 'DELETE') {
            return axiosInstance.delete(route, { params });
        } else {
            baseParameters.url = route;
            baseParameters.params = params;
            return axiosInstance.request(baseParameters);
        }
    };

    // Create aliases
    const get = ({ path, route, params } = {}) => request({ method: 'GET', path, route, params });
    const post = ({ path, route, data } = {}) => request({ method: 'POST', path, route, data });
    const del = ({ path, route, params } = {}) => request({ method: 'DELETE', path, route, params });

    return {
        get,
        post,
        delete: del,
        request,
    };
};

