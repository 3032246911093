import Vue from 'vue';
import Cookies from 'js-cookie';
import { env } from '@/bootstrap';
import { registries, UNIQUE_REGISTRY, ENV_CREATED, COOKIE_STORE_CREATED } from '@/events';

export default {
    namespaced: true,
    state: {
        cookieSuffix: 'prod',
        cookieDomain: '.harting.com',
        authTokenName: 'user_token',
        contactPersonName: 'contact_person',
        confirmedCookiesName: 'user_confirmed_cookies',
        termsAndConditionsName: 'user_accepted_terms_and_conditions',
        deniedNewsletterName: 'user_denied_newsletter',
    },
    mutations: {
        SET_STATE(state, { key, value }) {
            Vue.set(state, key, value);
        },
    },
    actions: {
        async init({ commit, getters, dispatch }) {
            await registries(UNIQUE_REGISTRY).wait(ENV_CREATED, { timeout: 1000 })
            commit('SET_STATE', { key: 'cookieSuffix', value: env('COOKIE_SUFFIX', 'prod') });
            commit('SET_STATE', { key: 'cookieDomain', value: env('COOKIE_DOMAIN', '.harting.com') });
            registries(UNIQUE_REGISTRY).emit(COOKIE_STORE_CREATED, getters);
        },
        setCookie({ state, getters: { cookieName } }, { name, value, args = {} }) {
            args = {
                domain: state.cookieDomain,
                secure: (location.protocol === 'https:'),
                ...args,
            };
            return Cookies.set(cookieName(name), value, args);
        },
        removeCookie({ getters: { cookieName} }, { name, args = {} }) {
            return Cookies.remove(cookieName(name), args);
        },
    },
    getters: {
        cookieName: ({ cookieSuffix }) => name => `${name}-${cookieSuffix}`,
        getCookie: (_, { cookieName }) => name => Cookies.get(cookieName(name)),
        authToken: (state, { getCookie }) => getCookie(state.authTokenName),
        contactPerson: (state, { getCookie }) => getCookie(state.contactPersonName),
        deniedNewsletter: (state, { getCookie }) => getCookie(state.deniedNewsletterName),
        confirmedCookies: (state, { getCookie }) => getCookie(state.confirmedCookiesName),
        termsAndConditions: (state, { getCookie }) => getCookie(state.termsAndConditionsName),
        authTokenName: state => state.authTokenName,
        contactPersonName: state => state.contactPersonName,
        confirmedCookiesName: state => state.confirmedCookiesName,
        termsAndConditionsName: state => state.termsAndConditionsName,
        deniedNewsletterName: state => state.deniedNewsletterName,
    }
};