export const dom = {
    cartHookId: 'cart-badge',
    cartHookContainer: '.fa.fa-shopping-cart',
    watchlistHookId: 'watchlist-badge',
    watchlistHookContainer: '.fa.fa-star',
};

export const store = {
    cartGetter: 'user/cartAmount',
    watchlistGetter: 'user/watchlistAmount',
}