<template>
    <a :href="drupalLink"
       class="lift-teaser__search-link">
        <div class="lift-teaser lift-teaser--compact">
            <div class="lift-teaser__image-container lift-teaser__image-container--search">
                <div class="lift-teaser__image"></div>
            </div>

            <div class="lift-teaser__caption">
                <div class="lift-teaser__category">
                    {{ $t(categoryKey) }}
                </div>
                
                <div class="lift-teaser__title" 
                     v-html="title"></div>
            </div>
        </div>
    </a>
</template>

<script>
    export default {
        name: 'DrupalArticle',
        props: {
            title: {
                type: String,
                required: true,
            },
            category: {
                type: String,
                required: true,
            },
            url: {
                type: String,
                required: true,
            },
        },
        computed: {
            drupalLink() {
                return this.$routes('URL_DRUPAL_BASE', this.url);
            },
            categoryKey() {
                return `category_${this.category.toLowerCase()}`;
            },
        },
    };
</script>

<style scoped>

</style>